<template>
  <v-dialog 
    max-width="800"
    :value="show"
    @input="$emit('close')"
  >
    <v-card max-width="800">
      <v-card-title>
        {{$t('rules.rules_title')}}
      </v-card-title>
      <v-card-text>
        {{$t('rules.rules_text')}}
      </v-card-text>
      <v-card-text>
        <v-list>
          <v-list-item
            link
            v-for="(rule, i) in rules"
            :key="i + 'ruule'"
            @click="openRuleEditor(rule)"
          >
            <v-list-item-content>
              {{rule['rule_name']}}
            </v-list-item-content>
            <v-spacer></v-spacer>
            <v-switch
              @click.stop=""
              :input-value="rule.active == 1"
              @change="toggleActive(rule)"
            ></v-switch>
            <v-menu right offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn fab small text v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn> 
              </template>
              <v-list dense>
                <v-list-item @click="startDeleteRule(rule.id)" link dense>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('delete')}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list>
      </v-card-text>
      <!-- <rule
        @remove="removeRule(i)"
        :rule="rule"
        @changed="ruleChanged($event, i)"
      ></rule> -->
      <v-btn
        class="ml-5 mb-5"
        rounded
        @click="openRuleEditor()"
        x-small
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary"
          @click="$emit('close')"
        >
          {{$t('ok')}}
        </v-btn>
      </v-card-actions>
      <!-- {{rules}} -->
    </v-card>
    <v-dialog 
      max-width="1200"
      v-model="addingRuleVisible"
      persistent
    >
      <AddGroupRule
        @close="addingRule = ''; addingRuleVisible = false"
        :key="addKey"
        :edit_rule="editingRule"
      ></AddGroupRule>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import AddGroupRule from '../../tag/AddGroupRule.vue'
// import rule from './Rule.vue'
export default {
  props: ['show'],
  components: { AddGroupRule },
  data: () => ({
    when: 'now',
    addingRuleVisible: false,
    addingRule: '',
    addKey: 0,
    editingRule: null
  }),
  created() {
    if(!this.rules?.length) {
      this.initRules()
    }
  },
  shortcuts: {
    escape: function() {
      this.$emit('close')
    },
    enter: function() {
      this.$emit('set')
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters('rule', [
      'rules'
    ])
  },
  methods: {
    ...mapMutations('rule', [
      'setRules',
      'addEmptyRule'
    ]),
    ...mapActions('rule', [
      'initRules',
      'saveRules',
      'deleteRule',
      'setActive'
    ]),
    ...mapActions('noti', [
      'error',
      'info'
    ]),
    startDeleteRule(id) {
      this.deleteRule(id)
        .then(() => this.info(this.$t('rule.rule_deleted')))
        .catch(e => this.error(e))
    },
    toggleActive(rule) {
      this.setActive({ active: !rule.active, id: rule.id })
        .then()
    },
    openRuleEditor(rule) {
      this.addingRuleVisible = true;
      this.editingRule = rule

      this.$nextTick(() => {
        this.addKey++;
      })
    },
    ruleChanged(rule, i) {
      let mod = [...this.rules]
      mod[i] = rule
      this.setRules(mod)
    },
    removeRule(index) {
      const rules = this.rules.filter((f, i) => i != index)
      this.setRules(rules)
    },
    startSaveRules() {
      this.saveRules().then()
    }
  }
}
</script>