<template>
  <v-sheet class="pb-3">
    <v-card 
      :elevation="isCurrent(plan) ? 0 : 6"
      class="mt-6 pt-6 mb-6"
      style="text-align: center;"
      :outlined="isCurrent(plan)"
    >
      <div class="mb-6">
        <v-icon
          :key="i + 'start'" 
          v-for="(_, i) in plan.is_the_best ? [1, 2, 3] : [1]" size="40" color="yellow darken-1"
        >mdi-star</v-icon>
      </div>
      <v-chip
        v-if="isCurrent(plan.id) || (!this.currentPlanId && plan.price_per_month == 0)" 
        class="mx-auto mb-6"
      >Current plan</v-chip>
      <v-chip 
        :color="plan.is_the_best ? 'primary' : ''"
        v-else
        class="mx-auto mb-6"
      >{{plan.chip_phrase}}</v-chip>
      <h1 class="font-weight-light" style="text-align: center;">{{plan.full_name}}</h1>
      <div class="font-weight-medium mx-auto" style="font-size: 3em;">
        {{ currency == 'eur' ? show_price : plan.usd_price }} {{ currency == 'eur' ? '€' : '$' }}
      </div>
      <div class="font-weight-medium ml-3" style="font-size: 1em;"> / {{ monthly ? $t('plans.month') : $t('plans.year') }} / {{ $t('plans.team') }}</div>
      <v-card-subtitle style="text-align: center">
        {{ plan.description }}
      </v-card-subtitle>
      <v-card-actions>
        <v-btn :loading="loading" :disabled="isCurrent(plan)" color="primary" block @click="clicked({price_id: plan.stripe_price_id, ios_id: plan.apple_store_identifier})">
          <div v-if="!!currentPlanId && currentPlanId != 1 && !isCurrent(plan)">
            <!-- Has plan already -->
            {{$t('plans.upgrade')}}
          </div>
          <div v-else-if="isCurrent(plan)">
            {{$t('plans.current_plan')}}
          </div>
          <div v-else>
            <!-- New customer -->
            {{$t('plans.start_free_trial')}}
          </div>
          <v-icon v-if="showDiamond(plan.short_name)" class="ml-5">mdi-diamond-stone</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-sheet class="pl-2">
      <!-- <div style="text-align: center; color: grey; text-transform: uppercase; font-size: 0.7em">{{ $t('plans.users_plural_pos') }}</div>
      <v-btn text x-large block :class="{'primary--text': plan.is_the_best}">
        {{ plan.users }} {{ plan.users > 1 ? $t('plans.user_plural') : $t('plans.user')}}
      </v-btn> -->
      <div style="text-align: center; color: grey; text-transform: uppercase; font-size: 0.7em">{{ $t('plans.cloud_storage') }}</div>
      <v-btn text x-large block :class="{'primary--text': plan.is_the_best}">
        {{ plan.total_hours_saved }} h
      </v-btn>
      <div style="text-align: center; color: grey; text-transform: uppercase; font-size: 0.7em">{{ $t('plans.video_quality') }}</div>
      <v-btn text x-large block :class="{'primary--text': plan.is_the_best}">
        {{ plan.quality > 800 ? '1080p' : '720p' }}
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiDiamondStone } from "@mdi/js";
export default {
  props: ['plan', 'currency', 'monthly'],
  data: () => ({
    icons: { mdiDiamondStone },
    loading: false
  }),
  methods: {
    ...mapActions('user', [
      'orderProduct',
      'openCustomerPortal'
    ]),
    clicked({price_id, ios_id}) {
      this.loading = true

      // InApp purchase
      if(this.isIos) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify({
          action: "buy_subscription",
          subscription_id: ios_id
        }));

        document?.ReactNativeWebView?.postMessage(JSON.stringify({
          action: "buy_subscription",
          subscription_id: ios_id
        }));

        return;
      }

      // Stripe web purchase
      this.orderProduct({
        // price_id: 'price_1KEFwZA2CHRD2pUGhaehEJpN'
        price_id: price_id
      })
        .then(e => {
          window.location = e.data.url
        })
        .catch(err => alert(err))
        .finally(() => this.loading = false)
    },
    showDiamond(plan) {
      switch(plan) {
        case 'pro':
          return true
      }
    },
    isCurrent(plan) {
      if(!this.currentPlanId) {
        return plan.price == 0 || plan.price == '0'
      }

      return plan.id == this.currentPlanId
    }
  },
  computed: {
    ...mapGetters('user', [
      'currentPlanId',
      'currentTeam'
    ]),
    ...mapGetters([
      'isIos'
    ]),
    show_price() {
      const m = this.plan.price_per_month
      const y = this.plan.price

      return this.monthly ? m : y
    }
  }
}
</script>