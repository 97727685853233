export default {
  methods: {
    secondsToPrettyTimer(time) {
      if(time == 0 || !time) return '00:00'
      time = time.toFixed(2)
      let minus = time < 0
      time = Math.abs(time)
      let text = time.toString()
      let seconds = text.split('.')[0]
      let rest = text.split('.')[1]
    
      if( parseInt(seconds) < 10 ) seconds = 0 + seconds;
      if(!rest || !rest[0]) rest = '00'
      if(!rest[1]) rest += '0'
      rest = rest[0] + rest[1]
    
      if(minus) return `-${seconds}:${rest}`
      return `${seconds}:${rest}`
    },
    secondsToPretty(time) {
      let hours = time / 60 / 60;
      hours = Math.floor(hours)
      let minutes = ( time - hours * 60 * 60 ) / 60;
      minutes = Math.floor(minutes)
      let seconds = time - ( hours * 60 * 60 ) - ( minutes * 60 )
      seconds = Math.floor(seconds)
      hours = '0' + hours.toString()
      if(minutes < 10) minutes = '0' + minutes.toString()
      if(seconds < 10) seconds = '0' + seconds.toString()
      if(hours != '00') return `${hours}:${minutes}:${seconds}`;
      return `${minutes}:${seconds}`;
    },
    getSmallestDatapoint(data) {
      let smol;

      data.forEach(d => {
        if(!smol || d.time_from_first < smol.time_from_first) {
          smol = d
        }
      })

      return smol
    },
    getLargestDataPoint(data) {
      let largest;

      data.forEach(d => {
        if(!largest || d.time_from_first > largest.time_from_first) {
          largest = d
        }
      })

      return largest
    },
    totalTime(data) {
      if(!data) return
      return this.getLargestDataPoint(data)?.time_from_first
    },
    dataStarttime(data) {
      if(!data) return
      return this.getSmallestDatapoint(data)?.video_time
    },
    getPointsFromClips(clips) {
      let first = clips[0].starttime - 5
      let second
      clips.forEach((c, i) => {
        if(i > 0 && - clips[i - 1].starttime + c.starttime > 60 * 8) second = c.starttime - 5
      })

      return [first, second]
    },
    findRelevantClip(time, clips) {
      if(!clips || !clips.length) return null
      let numOfClips = clips.length;

      if(numOfClips == 0) return null
      if(numOfClips == 1) {
        if(time > clips[0].starttime && time < clips[0].endtime) return clips[0]
        return null
      }

      let i = parseInt(numOfClips / 2)

      let checkClip = clips[i]

      if(time > checkClip.starttime && time < checkClip.endtime) return clips[i]
      else if(time < checkClip.starttime) return this.findRelevantClip(time, clips.slice(0, i))
      else if(time > checkClip.endtime) return this.findRelevantClip(time, clips.slice(i + 1))
      return null
    }
  }
}