<template>
  <div
    id="bar"
    style="
      display: flex;
      flex-direction: row;
      height: 4em;
      align-items: center;
      position: relative;
    "
    :style="presentation && $vuetify.breakpoint.mobile ? 'justify-content: space-between; margin-inline: 2em' : 'justify-content: center;'"
  >
    <v-card
      v-if="timeDuration && presentation && !$vuetify.breakpoint.mobile && !loading"
      :style="{ 'padding-top': $vuetify.breakpoint.mobile ? '0' : '1vh' }"
      style="
        position: absolute;
        bottom: 0.6em;
        left: 1em;
        width: 8em;
        height: 3em;
        z-index: 4;
      "
    >
      <v-card-title
        style="display: flex; justify-content: center; align-items: center"
        :style="{
          'font-size': $vuetify.breakpoint.mobile ? '0.6em' : 'calc(100%)',
        }"
        class="ma-0 pa-0"
      >
        {{ $t("presentation.time") + timeDuration + "s" }}
      </v-card-title>
    </v-card>
    <v-chip style="
        position: absolute;
        bottom: 1.4em;
        left: 1.4em;
        z-index: 4;
      "  v-if="time">
      {{pretty}}
    </v-chip>
    <v-btn
      v-if="presentation"
      class="mr-3"
      @click="switchItem(-1)"
      :small="$vuetify.breakpoint.mobile"
      :large="!$vuetify.breakpoint.mobile"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <v-btn
      v-if="!hidePlay"
      class="mr-3"
      :small="$vuetify.breakpoint.mobile"
      :large="!$vuetify.breakpoint.mobile"
      @click="toggle()"
    >
      <v-icon v-if="!playing">mdi-play</v-icon>
      <v-icon v-else>mdi-pause</v-icon>
    </v-btn>
    <playback-rate-button v-if="!hideRate"></playback-rate-button>
    <v-btn
      small
      class="mr-2"
      v-if="filter"
      @click="settingsOpen = !settingsOpen"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>
    <v-btn
      v-if="presentation"
      :small="$vuetify.breakpoint.mobile"
      :large="!$vuetify.breakpoint.mobile"
      class="ml-1 mr-3"
      @click="switchItem(1)"
      ><v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <v-btn v-if="showCommentBtn"
      :style="$vuetify.breakpoint.mobile ? '' : 'position: absolute; right: 1.5em;'"
      :small="$vuetify.breakpoint.mobile"
      :large="!$vuetify.breakpoint.mobile"
      @click="toggleComment"
    >
      <v-icon v-if="!commentVisible">mdi-comment-text-outline</v-icon>
      <v-icon v-else>mdi-comment-remove-outline</v-icon>
    </v-btn>
    <v-dialog max-width="600" v-model="settingsOpen">
      <FilterSettings :filter="filter" @close="settingsOpen = false"></FilterSettings>
    </v-dialog>
    <div v-if="!hideTimes">
      <v-btn
        small
        v-for="(time, i) in times"
        style="margin-right: 2px; padding: 0;"
        :key="'node' + i"
        @click="skip(time)"
      >
        {{ time }}
      </v-btn>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PlaybackRateButton from "./PlaybackRateButton.vue";
import FilterSettings from "@/components/filters/FilterSettings.vue";
import timeMixin from '@/utils/player/time';

export default {
  components: { PlaybackRateButton, FilterSettings },
  mixins: [ timeMixin ],
  props: [
    "showCommentBtn",
    "filter",
    "hideRate",
    "showMostAnyway",
    "hidePlay",
    "hideTimes",
    "presentation",
    "time"
  ],
  mounted() {
    this.currentList = this.folders;
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.onResize();
      });
    });
  },
  data: () => ({
    settingsOpen: false,
    windowWidth: window.innerWidth,
  }),
  methods: {
    ...mapActions("presentation", ["switchItem", "previousItem"]),
    ...mapActions("comment", ["toggleComment"]),
    ...mapActions("player", ["play", "pause", "toggle", "skip"]),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapGetters("player", ["playing", "accurateCurrentTime"]),
    ...mapGetters("folder", ["folders", "allChildren"]),
    ...mapGetters("presentation", ["activeItemId", "showingText", "timeDuration", "loading"]),
    ...mapGetters("comment", ["commentVisible", "comment"]),
    times() {
      if (this.showMostAnyway) return [-5, -1, -0.1, -0.01, 0.01, 0.1, 1, 5];
      if (this.windowWidth > 1400)
        return [-30, -5, -1, -0.1, -0.01, 0.01, 0.1, 1, 5, 30];
      if (this.windowWidth > 1200)
        return [-5, -1, -0.1, -0.01, 0.01, 0.1, 1, 5];
      if (this.windowWidth > 1100) return [-5, -1, -0.1, 0.1, 1, 5];
      if (this.windowWidth > 900) return [-1, -0.1, 0.1, 1];
      return [];
    },
    barStyle() {
      let { total, current } = this.currentTimeAndDuration;

      let bar = document.querySelector(".io-bar");
      let w;

      if (bar) w = bar.offsetWidth;

      if (!current || !total) return { width: "0px;" };

      let style = {
        width: `${parseInt(w * (current / total))}px`,
      };
      return style;
    },
    commentButtonClass() {
      if(!this.$vuetify.breakpoint.mobile) return 'comment'
      return 'comment-mobile'
    },
    pretty() {
      return this.secondsToPrettyTimer(this.accurateCurrentTime - this.dataStarttime(this.time.data.data))
    }
  },
  watch: {
    settingsOpen(newvalue, oldvalue) {
      if (!newvalue && oldvalue) {
        this.$emit('apply')
      }   
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.comment {
  position: absolute; right: 20px
}
.comment-mobile {
  margin-left: 1em;
}

@media (orientation: portrait) {
  #bar {
    justify-content: space-between;
  }
}
</style>
