<template>
  <div>
    <v-btn
        :fab="fab"
        :small="size == 'small'"
        :x-small="size == 'x-small'"
        :large="size == 'large'"
        :x-large="size == 'x-large'"
        :block="block"
        @click.stop="showShareDialog = true; getLink(); $emit('save')"
      >
        <v-icon>
          mdi-share
        </v-icon>
        <span v-if="!fab">
          {{ $t('share') }}
        </span>
      </v-btn>
      <v-dialog max-width="1000" outlined v-model="showShareDialog">
        <v-card>
          <v-card-title>
            {{ $t('share') }}
          </v-card-title>
          <v-card-text>
            <v-select
              :label="$t('valid_for')"
              :items="items"
              v-model="valid_days"
              @change="url = null; getLink()"
            ></v-select>
          </v-card-text>
          <v-sheet class="mx-5 pa-4" style="background-color: lightgrey; border-radius: 5px; text-align: center;">
            <span v-if="url">
              {{ url }}
            </span>
            <div v-else style="display: inline-block; align-self: center; height: 40px; width: 400px; background-color: grey; border-radius: 5px;"></div>
            <v-btn @click="copyToClipBoard()" class="ml-4">
              <v-icon>
                mdi-content-copy
              </v-icon>
            </v-btn>
          </v-sheet>
          <v-divider class="my-4"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!url" @click="shareViaEmail()">
              <v-icon class="mr-2" color="blue">mdi-email-outline</v-icon>
              Email
            </v-btn>
            <v-btn :disabled="!url" @click="shareViaWhatsapp()" class="ml-2">
              <v-icon class="mr-2" color="green">mdi-whatsapp</v-icon>
              Whatsapp
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: [ 
    'resource_type', // map, time, clip, presentation, video, filter, folder
    'resource_id', // respective id of the resource 
    'size', // button size [ small - x-small - large - x-large ]
    'fab',
    'block'
  ],
  data: () => ({
    showShareDialog: false,
    valid_days: 365,
    items: [
      { text: '1 day', value: 1 },
      { text: '1 week', value: 7 },
      { text: '1 month', value: 30 },
      { text: '1 year', value: 365 }
    ],
    url: null
  }),
  computed: {
    ...mapGetters(['isApp'])
  },
  methods: {
    getLink() {
      this.url = null
      this.getShareLink({ 
        resource_type: this.resource_type,
        resource_id: this.resource_id,
        valid_days: this.valid_days
      })
      .then(e => {
        this.url = e
      })
      .catch(e => this.error(e))
    },
    copyToClipBoard() {
      if(!this.url) return

      navigator.clipboard.writeText(this.url);
      this.success(this.$t('copied_to_clipboard'))
    },
    shareViaEmail() {
      window.location.href = `mailto:?body=${this.url}&subject=Tiimi.io resource`;
    },
    shareViaWhatsapp() {
      let url;
      // const query = `text=Check this ${this.resource_type}: ${this.url}`
      const query = `text=${this.url}`
      if(this.isApp) {
        url = `whatsapp://send?${query}`
      } else {
        url = `https://web.whatsapp.com/send?${query}`
      }

      window.open(url, '_blank')
    },
    ...mapActions('share', [
      'getShareLink'
    ]),
    ...mapActions('noti', [
      'error',
      'success'
    ])
  }
}
</script>