<template>
  <v-container class="mx-auto pb-16 pt-8" style="overflow-y: scroll; height: 100%; max-width: 1200px; padding: 0 30px 0 30px;">
    <v-card-title v-if="noPlansCurrently" class="justify-center">
      {{$t('plans.we_will_remind_paragraph')}}
    </v-card-title>
    <v-card-subtitle style="text-align: center;" v-if="noPlansCurrently" class="justify-center mx-auto mt-2">
      {{$t('plans.quit_any_time')}}
    </v-card-subtitle>
    <!-- <div class="mb-2" style="display: flex; justify-content: center;">
      <v-btn-toggle mandatory v-model="currency">
        <v-btn x-small value="eur">
          EUR €
        </v-btn>
        <v-btn x-small value="usd">
          USD $
        </v-btn>
      </v-btn-toggle>
    </div> -->
    <div v-if="!$route.query.plan_id" style="display: flex; justify-content: center;">
      <v-btn-toggle class="ml-10" mandatory v-model="monthly">
        <v-btn small :value="true">
          {{ $t('monthly') }}
        </v-btn>
        <v-btn small :value="false">
          {{ $t('yearly') }}
        </v-btn>
      </v-btn-toggle>
      <v-chip class="mt-1 ml-3" x-small color="primary" outlined label>
        {{ $t('save_in_money') }} 16%!
      </v-chip>
    </div>
    <div class="mt-5" style="text-align: center;">
      <v-btn @click="goTo('https://app.websitepolicies.com/policies/view/6pfz8prt')" x-small text>Privacy policy</v-btn>
      <v-btn @click="goTo('https://www.apple.com/legal/internet-services/itunes/dev/stdeula/')" x-small text>Terms of use</v-btn>
    </div>
    <v-row style="justify-content: center;">
      <v-col cols="12" md="4" v-for="(plan) in showPlans" :key="plan.id">
        <PlanCard
          :monthly="monthly"
          :currency="currency"
          :plan="plan"
        ></PlanCard>
      </v-col>
    </v-row>
    <div class="d-flex justify-center">
      <v-btn @click="openPlans()" color="primary">
        {{ $t('compare_plans') }}
        <v-icon class="ml-4">mdi-open-in-new</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import PlanCard from '@/components/plans/PlanCard'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { PlanCard },
  data: () => ({
    currency: 'eur',
    monthly: false
  }),
  created() {
    this.initPlans()
  },
  methods: {
    ...mapActions('auth', [
      'initPlans'
    ]),
    goTo(uri) {
      window.open(uri, '_blank')
    },
    openPlans() {
      this.goTo('https://tiimi.io/pricing')
    }
  },
  computed: {
    ...mapGetters('auth', [
      'plans'
    ]),
    ...mapGetters('user', [
      'currentPlanId',
      'currentTeam',
      'currentTeamId'
    ]),
    showPlans() {
      const show_plan = this.$route.query.plan_id

      if(show_plan) {
        return this.plans.filter(p => p.id == show_plan)
      }

      if(!this.currentPlanId) return this.plans.filter(p => p.monthly == this.monthly)
      if(!this.plans) return []

      const currentPlan = this.plans.find(p => p.id == this.currentPlanId)
      if(!currentPlan) return this.plans.filter(p => p.monthly == this.monthly)

      return this.plans.filter(plan => plan.price_per_month >= currentPlan.price_per_month).filter(p => p.monthly == this.monthly)
    },
    noPlansCurrently() {
      return !this.currentPlanId
    }

  }
}
</script>