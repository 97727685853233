<template>
  <div style="width: calc(100%); position: relative; aspect-ratio: 16/9;">
    <div @mouseover="SET_SCROLL_ENABLED(true)" @mouseout="SET_SCROLL_ENABLED(false)" id="io-player" ref="player"></div>
    <div v-if="show_time_overlay" style="position: absolute; top: 10px; left: 10px; font-size: 20px; background-color: white; border-radius: 5px; padding: 0 30px; margin-left: calc(50% - 50px)">
      {{ secondsToPretty(currentTime) }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Clappr, {} from 'clappr';
import time from '@/utils/player/time'

export default {
  props: ['options', 'filter'],
  mixins: [ time ],
  mounted() {
    this.initPlayer(this.options)
    this.player.attachTo(this.$refs.player);

    var self = this

    this.player.listenTo(
      this.player.core.mediaControl,
      Clappr.Events.MEDIACONTROL_HIDE,
      function(){self.SET_MEDIACONTROL_VISIBLE(false)}
    );

    this.player.listenTo(
      this.player.core.mediaControl,
      Clappr.Events.MEDIACONTROL_SHOW,
      function(){self.SET_MEDIACONTROL_VISIBLE(true)}
    );
  },
  created() {
    var self = this
    window.addEventListener("wheel", self.handleScroll);
    window.addEventListener("keyup", self.keyUp);
  },
  shortcuts: {
    keydown: function (e) {
      if(e.keyCode == 16) this.SET_SLOW_SCROLL(true)
      return this.keybinds
    },
    toggle: function() {
      this.toggle()
    },
    skipForward: function() {
      this.skip(5)
    },
    skipForwardLarge: function() {
      this.skip(30)
    },
    skipForwardSmall: function() {
      this.skip(1)
    },
    skipBackward: function() {
      this.skip(-5)
    },
    skipBackwardLarge: function() {
      this.skip(-30)
    },
    skipBackwardSmall: function() {
      this.skip(-1)
    },
    rateUp: function() {
      if(!this.filter) this.playbackRateUp()
      else this.$emit('last')
    },
    rateDown: function() {
      if(!this.filter) this.playbackRateDown()
      else this.$emit('next')
    }
  },
  computed: {
    ...mapGetters('player', [
      'player',
      'show_time_overlay',
      'currentTime',
      'slow_scroll'
    ]),
    ...mapGetters([
      'keybinds'
    ])
  },
  methods: {
    ...mapActions('player', [
      'initPlayer',
      'toggle',
      'skip',
      'playbackRateUp',
      'playbackRateDown',
      'changePlaybackRate',
      'destroy',
      'handleScroll'
    ]),
    ...mapMutations('player', [
      'SET_MEDIACONTROL_VISIBLE',
      'SET_SCROLL_ENABLED',
      'SET_SLOW_SCROLL'
    ]),
    keyUp(e) {
      if(e.keyCode == 16) this.SET_SLOW_SCROLL(false)
    }
  },
  beforeDestroy() {
    this.changePlaybackRate(1);
    this.destroy();
    window.removeEventListener("scroll", this.handleScroll, true);
  }
}
</script>

<style lang="scss" scoped>
  #io-player {
    // height: 100% !important;
    aspect-ratio: 16/9;
    width: 100% !important;
    min-height: 100px;
    z-index: 100; 
  }
</style>