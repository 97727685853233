<template>
  <v-card>
    <v-sheet class="pa-6">
      <v-sheet style="border-radius: 5px;" class="mb-6 pa-2 white--text" color="error" v-if="(exporting_clip && !exporting_clip.encoded) || streaming">
        <v-span v-if="!streaming">
          {{ $t('exports.video_encoding_long') }}
        </v-span>
        <v-span v-else>
          {{ $t('exports.video_streaming_long') }}
        </v-span>
      </v-sheet>
      <div class="text-h4 mb-3">
        {{ $t('exports.dialog_title') }}
      </div>
      <span v-if="!hasPastExports && !past_export && !export_ongoing" class="mb-3">{{ $t('exports.export_clip_subtitle') }}</span>
      <span v-else-if="past_export && past_export.length < 2 && !export_ongoing" class="mb-3">{{ $t('exports.past_found') }}</span>
      <span v-else-if="past_export && past_export.length > 1 && !export_ongoing" class="mb-3">{{ $t('exports.past_found_multi') }}</span>
      <span v-else-if="export_ongoing" class="mb-3">{{ $t('exports.export_started') }}</span>
      <div class="mt-3">
        <div v-if="!hasPastExports && exporting_clip && !past_export && !export_ongoing && !uploadingImages" style="display: inline;">
          <div class="text-h6">
            <h4 style="display: inline">{{$t('exports.clip')}}</h4>
            <span style="display: inline" class="ml-2">{{ exporting_clip.title }}</span>
          </div>
          <div class="text-h6">
            <h4 style="display: inline">{{ $t('exports.duration')}}</h4>
            <span style="display: inline" class="ml-2">{{secondsToPretty(exporting_clip.endtime - exporting_clip.starttime)}}</span>
          </div>
        </div>
        <v-progress-linear v-if="loading_clip" class="my-4" indeterminate></v-progress-linear>
        <div v-if="uploadingImages" style="width:100%; display: flex; align-items: center; justify-content: center;">
          <div style="text-align: center; width: 50%">
            {{ $t('exports.uploading_graphics')}}
            <v-progress-linear :value="overallProgress" class="my-4"></v-progress-linear>
          </div>
        </div>
        <div v-if="past_export || export_ongoing" style="width: 100%">
            <ExportItems :key="key" :single="true"></ExportItems>
        </div>
      </div>
    </v-sheet>

    <!-- clip -->
    <!-- mp4 vs gif -->
    <!-- include comments (if comments available) -->
    <!-- include graphics (on by default if graphics otherwise hidden) -->
    <!-- if one clip - the export is done inline -->

    <v-divider></v-divider>
    <v-card-actions>
      <v-btn @click="$emit('close')">
        {{ $t('exports.return') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="streaming" color="primary" :loading="loading_clip || uploadingImages" @click="export_ongoing ? $emit('close') : createImagesAndUploadThem()">
        <v-icon :class="export_ongoing ? '' : 'mr-3'">{{export_ongoing ? 'mdi-check' : 'mdi-download-outline'}}</v-icon>
        {{ export_ongoing ? '' : $t('exports.start_export') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Konva from 'konva'
import { v4 as uuidv4 } from 'uuid';
import time from '@/utils/player/time';
import ExportItems from '../../exports/ExportItems.vue'

export default {
  components: {
    ExportItems
  },
  props: ['clip_id'],
  mixins: [time],
  data: () => ({
    key: 0,
    hasPastExports: true
  }),
  async created() {
    await this.startClipExport(this.clip_id)
      .catch(e => {
        this.error(e)
      })
    if(this.past_export) this.hasPastExports = true
    else this.hasPastExports = false
  },
  beforeDestroy() {
    this.clearAll()
  },
  methods: {
    ...mapActions('export', [
      'startClipExport',
      'exportByClipId',
      'downloadItem',
      'parseCreated',
      'exportByExportId',
      'clearAll',
      'displayExportState'
    ]),
    ...mapActions('noti', [
      'error'
    ]),
    ...mapActions('clip', [
      'clipById'
    ]),
    ...mapActions('upload', [
      'uploadImages',
      'startClipProcessing'
    ]),
    ...mapMutations('export', [
      'SET_PAST_EXPORT',
      'SET_EXPORT_ONGOING',
      'SET_REFRESHING'
    ]),
    async createImagesAndUploadThem() {
      this.SET_PAST_EXPORT(null)

      const has_graphics = this.exporting_clip.graphics?.layers?.filter(l => l.type != 'slowdown')?.length > 0
      let urls;

      if(has_graphics) {
        const images = this.exportLayers()
        try {
          urls = await this.uploadImages({ images, clip_id: this.clip_id })
        } catch(e) {
          console.log("Error!: ", e)
          return
        }
      }

      const id = uuidv4()
      
      const uploadData = {
        exporter: this.user.email,
        team_id: parseInt(this.currentTeamId),
        has_graphics: has_graphics,
        graphics_urls: urls,
        export_id: id,
        clip_title: this.exporting_clip.title,
        clip: this.exporting_clip,
        export_started: Date.now(),
        output_dimensions: {
          height: 1080,
          width: 1080 * 16 / 9
        },
      }

      this.key++

      this.startClipProcessing(uploadData)
        .then(e => {
          console.log(e)
          this.displayExportState(true)
        })
        .catch(e => {
          console.log(e)
        })
    },
    exportLayers() {
      let graphics = this.graphics;
      // if(!graphics || graphics.length < 1) return

      // Create an off-screen Konva Stage
      let width = this.exporting_clip.graphics.original_video_width;
      let height = width * 9 / 16;

      var images = []

      // Iterate over each layer in the graphics data
      graphics.layers.forEach((layer) => {
        const stage = new Konva.Stage({
          container: document.createElement('div'), // Create a div not attached to the DOM
          width, // Set the desired width for your stage
          height // Set the desired height for your stage
        });
        const konvaLayer = new Konva.Layer(); // Create a new Konva layer
        stage.add(konvaLayer); // Add the layer to the stage

        // Filter objects by the current layer ID and add them to the layer
        graphics.objects.filter((obj) => obj.layer_id === layer.id).forEach((object) => {
          let shape;
          switch (object.type) {
            case 'line':
              shape = new Konva.Line(object);
              break;
            case 'free':
              shape = new Konva.Line(object);
              break;
            case 'ellipse':
              shape = new Konva.Ellipse(object);
              break;
            case 'arrow':
              shape = new Konva.Arrow(object);
              break;
            case 'rect':
              shape = new Konva.Rect(object);
              break;
            case 'text':
              shape = new Konva.Text(object);
              break;
            default:
              console.warn('Unsupported shape type:', object.type);
              break;
          }

          if (shape) {
            konvaLayer.add(shape); // Add the shape to the layer
          }
        });

        konvaLayer.draw(); // Draw the layer's content
        // Export the stage as an image
        const imageDataUrl = stage.toDataURL({
          pixelRatio: 1920 / this.exporting_clip.graphics.original_video_width, // Adjust the pixel ratio for higher resolution images
        });

        // Handle the exported image URL (e.g., send to server, download, etc.)
        images.push({
          layer_id: layer.id,
          image_data_url: imageDataUrl
        })  
      });

      return images;
    },
    downloadImage(imageDataUrl, fileName = 'exported_image.png') {
      // Create a temporary anchor element
      const downloadLink = document.createElement('a');
      downloadLink.href = imageDataUrl;
      downloadLink.download = fileName; // Set the filename for the download

      // Append the anchor to the body, click it, and then remove it
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    async getExportItem() {
      let item = await this.exportByClipId(this.exporting_clip.id)
      return item[1]
    },
    async openExports() {
      this.$router.replace('/exports')
    },
  },
  computed: {
    ...mapGetters('export', [
      'loading_clip',
      'exporting_clip',
      'past_export',
      'export_ongoing',
      'loading_exports'
    ]),
    ...mapGetters('upload', [
      'overallProgress',
      'uploadingImages'
    ]),
    ...mapGetters('user', [
      'currentTeamId',
      'user'
    ]),
    streaming() {
      return this.exporting_clip.mp4_url.includes('.m3u8')
    },
    graphics() {
      return this.exporting_clip.graphics
    },

  }
}
</script>