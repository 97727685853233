<template>
  <div style="height: 100vh; width: 100vw">
    <v-btn
      @click="$router.push('/videos')" 
      class="share-back-button"
      style="position: absolute; left: 10px; top: 10px; z-index: 1000;" 
      small
    >
      <v-icon class="mr-3">mdi-arrow-left</v-icon>
      {{ $t('go_to_videos') }}
    </v-btn>
    <Video v-if="video" :manual_video="video"></Video>
    <play-clip v-if="clip" :visible="!!clip" :unclosable="true" :manual_clip="clip"></play-clip>
    <play-clip v-if="time" :visible="!!time" :unclosable="true" :manual_time="time"></play-clip>
    <Library :view_only="true" v-if="folder" :folder_id="folder.id" :unclosable="true"></Library>

    <!-- expired modal -->
    <v-dialog max-width="800" :value="not_valid">
      <v-card>
        <v-card-title style="text-align: center;">
          {{ $t('link_not_valid') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-btn color="primary" @click="$router.push('/')">
            {{ $t('go_to_tiimi') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Video from '@/views/Video'
import Library from '@/views/Library'
import PlayClip from '@/components/video/PlayClip.vue'

export default {
  components: { Video, PlayClip, Library },
  data: () => ({
    video: null,
    clip: null,
    time: null,
    folder: null,
    not_valid: false
  }),
  created() {
    this.fetchShareData(this.$route.params.code)
      .then(e => {
        if(e.share.resource_type == 'video') this.video = e
        if(e.share.resource_type == 'clip') this.clip = e
        if(e.share.resource_type == 'folder') this.folder = e
        if(e.share.resource_type == 'time') this.time = e
      })
      .catch(e => {
        this.not_valid = true
        this.error(e)
      })
  },
  methods: {
    ...mapActions('share', [
      'fetchShareData'
    ]),
    ...mapActions('noti', [
      'error'
    ])
  },
  computed: {
    ...mapGetters('user', [
      'loggedIn'
    ])
  }
}
</script>

<style lang="scss" scoped>
.share-back-button {
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
</style>