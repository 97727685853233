<template>
  <v-card class="mx-auto">
    <v-card-text>
      <v-card-title>
        {{$t('maps.choose_map')}}
      </v-card-title>
      <div style="text-align: center;" v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-row v-if="!loading">
        <v-col
          cols="12"
          md="6"
          style="text-align: center;"
          v-for="base in map_bases"
          :key="'mapbase' + base.id"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              height="200"
              :elevation="hover ? 6 : 2"
              width="200"
              class="mx-auto"
              style="cursor: pointer;"
              @click.stop="startDrawing(base)"
            >
              <v-overlay 
                :value="hover" 
                :absolute="true"
                @click.stop="startDrawing(base)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-overlay>
              <v-img 
                :src="base.url"
                max-height="200"
                max-width="200"
                contain
              ></v-img>
              <!-- <h3 color="primary" style="text-align: center;">
                {{$t('maps.add_new')}}
              </h3> -->
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn 
        @click.stop="$emit('close')"
      >
        {{$t('cancel')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import a from '@/utils/axios/axios'
import { mapGetters, mapActions } from 'vuex'
export default {
  mounted() {
    this.loading = true
    a.get(`/maps/base/team/${this.currentTeamId}`)
      .then(e => {
        this.map_bases = e.data
        // if(e.data.length == 1) {
        //   this.$emit('startDrawing', e.data[0])
        // }
      })
      .catch(e => this.error(e))
      .finally(() => this.loading = false)
  },
  data: () => ({
    map_bases: [],
    loading: false
  }),
  computed: {
    ...mapGetters('user', [
      'currentTeamId'
    ])
  },
  methods: {
    ...mapActions('noti', [
      'error'
    ]),
    startDrawing(base) {
      this.$emit('startDrawing', base)
    }
  }
}
</script>