<template>
  <div>
    <v-select 
      class="mb-2"
      hide-details
      v-if="template"
      :value="template.id" 
      item-text="template_name" 
      item-value="id" 
      @input="chooseTemplateById($event)" 
      :items="templates.concat({ id: 'manual', template_name: 'Manual tagging' })" 
      solo 
      dense
      @click:append-outer="$router.push(`/edit-button-template/${template.id}/team`)"
    >
      <template v-slot:append-outer>
        <v-menu v-if="template.id !== 'manual'" width="200" offset-y offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" style="margin-top: -10px;" small fab text>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$router.push(`/edit-button-template/${template.id}/team`)">
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              Edit template
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="deleting = template.id">
              <v-list-item-icon>
                <v-icon color="red">mdi-delete</v-icon>
              </v-list-item-icon>
              Delete template
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:append-item>
        <v-divider></v-divider>
        <v-list-item v-if="!$vuetify.breakpoint.mobile" @click="$router.push('/create-team-template')" dense>
          <v-list-item-title>Create template</v-list-item-title>
          <v-list-item-icon>
            <v-icon>
              mdi-plus
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </v-select>
    <confirmation-dialog
      :show="deleting"
      @decline="deleting = false"
      @accept="startDeleteTemplate()"
      text="Are you sure?"
      subText="Are you sure you want to delete this button template?"
      btnText="delete"
      btnColor="red"
    ></confirmation-dialog>  
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
export default {
  components: { ConfirmationDialog },
  data: () => ({
    deleting: false
  }),
  computed: {
    ...mapGetters('tagging', [
      'template'
    ]),
    ...mapGetters('template', [
      'templates'
    ]),
  },
  methods: {
    ...mapMutations('tagging', [
      'SET_TEMPLATE',
      'SET_PRE_ANSWERS',
      'SET_EDITING_FORM_ITEMS'
    ]),
    ...mapActions('tagging', [
      'chooseTemplateById'
    ]),
    ...mapActions('template', [
      'deleteTemplate'
    ]),
    ...mapActions('noti', [
      'error',
      'info'
    ]),
    startDeleteTemplate() {
      this.deleteTemplate(this.deleting)
        .then(() => {
          this.deleting = null
          this.info('Template deleted')
          this.$nextTick(() => {
            if(this.templates?.length) this.chooseTemplateById(this.templates[0]?.id)
            else this.chooseTemplateById({ id: 'manual' })
          })
        })
        .catch(e => {
          this.error(e)
        })
    },
  }
  
}
</script>