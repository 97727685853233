<template>
  <v-card id="card-container" style="height: 80vh;">
    <v-row>
      <v-col class="pa-0" cols="12" :md="dense ? '12' : '6'" order="2" >
        <v-sheet style="text-align: center;" class="py-5 px-5 my-5">
          <v-form 
            ref="form"
            :lazy-validation="true"
          >
            <v-text-field
              v-if="!point_only"
              solo
              v-prevent-keys
              class="mx-auto"
              :label="$t('maps.title')"
              v-model="title"
              :rules="required"
            >
            </v-text-field>
            <v-textarea 
              v-if="!point_only"
              solo
              v-prevent-keys
              :label="$t('comments')"
              v-model="description"
            ></v-textarea>
          </v-form>
          <div style="text-align: left;">
            <choose-folder-btn
              v-if="false"
              style="float: left;"
              v-model="folder"
            ></choose-folder-btn>
            <div v-if="!point_only">
              <v-btn @click="$emit('close'); points = []">{{$t('cancel')}}</v-btn> 
              <v-btn 
                class="ml-4" 
                color="primary"
                @click="modify ? startEditMap() : startAddMap()"
              >{{$t('save')}}</v-btn> 
            </div>
          </div>
        </v-sheet>
      </v-col>
      <v-col
        id="col-container"
        style="text-align: center; position: relative; width: 500px;"
        cols="12"
        :md="dense ? '12' : point_only ? '12' : '6'"
        class="pa-0"
      >
        <Map 
          style="cursor: crosshair;"
          :loading="true"
          :base="base || get_map_base_id"
          @input="points = $event"
          :value="points"
        ></Map>
        <div></div>
        <div class="mt-3" v-if="point_only">
          <v-btn @click="$emit('close'); points = []">{{$t('cancel')}}</v-btn> 
          <v-btn 
            class="ml-4" 
            color="primary"
            @click="modify ? startEditMap() : startAddMap()"
          >{{$t('save')}}</v-btn> 
        </div>
        <!-- <img
          id="field"
          style="border: 1px solid grey; max-width: 70vw; max-height: 70vh; margin-top: 40px; margin-bottom: 40px; cursor: crosshair;"
          :src="base.url"
          contain
          @click="clicked($event)"
          aspect-ratio="1"
        /> -->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Map from '@/components/maps/Map'
import a from '@/utils/axios/axios'
import { mapActions } from 'vuex'
import ChooseFolderBtn from '../folder/ChooseFolderBtn.vue'

export default {
  components: { Map, ChooseFolderBtn },
  props: ['base', 'modify', 'dense', 'point_only'],
  data: () => ({
    editor: null,
    points: [
    ],
    title: '',
    description: '',
    folder: null,
    required: [
      v => !!v || 'Required',
    ]
  }),
  created() {
    if(this.modify) {
      this.title = this.modify.title
      this.description = this.modify.description

      a.get(`/maps/${this.modify.id}/points`)
        .then(e => this.points = e.data)
        .catch(e => this.error(e))
    }
  },
  methods: {
    ...mapActions('noti', [
      'success', 
      'error',
      'info'
    ]),
    ...mapActions('map', [
      'postMap',
      'editMap'
    ]),
    startAddMap() {
      if(this.point_only) {
        this.$emit('saved', this.points)
        return
      }

      const validated = this.$refs.form.validate()
      if(!validated) return

      this.postMap({
        title: this.title, 
        description: this.description,
        points: this.points,
        map_base_id: this.base.id
      })
      .then(() => {
        this.points = []
        this.success(this.$t('maps.map_added'))
        this.$emit('close')
      })
      .catch(e => this.error(e))
    },
    startEditMap() {
      this.editMap({
        id: this.modify.id,
        title: this.title,
        description: this.description,
        points: this.points
      })
      .then(() => {
        this.success('Map edited!')
        this.$emit('close')
      })
      .catch(e => {
        this.error(e)
      })
    }
  },
  computed: {
    get_map_base_id() {
      return this.modify ? {url: this.modify.url} : null
    }
  }
}
</script>

<style lang="scss" scoped>
  #konvacont {
    cursor: crosshair;
  }
</style>