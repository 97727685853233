<template>
  <v-dialog @click:outside="$emit('close')" max-width="600" v-model="show">
    <v-card>
      <v-btn @click="$emit('close')" small fab style="position: absolute; top: 5px; right: 5px;">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        {{$t('ticks.settings')}}
      </v-card-title>
      <v-card-text>
        <!-- <v-switch
          :value="autosave"
          @change="SET_AUTOSAVE($event || false)"
          v-model="autosave"
          :label="$t('ticks.autosave_on')"
        ></v-switch>
        <v-slider
          min="1" 
          max="10" 
          :disabled="!autosave"
          :value="autosave_delay"
          @input="SET_AUTOSAVE_DELAY($event)" 
          :label="$t('ticks.save_delay') + ' ' + autosave_delay + 's'"
        ></v-slider> -->
        <v-switch
          :value="adding_map_point_saves_tag"
          @change="SET_ADDING_MAP_POINT_SAVES_TAG($event || false)"
          v-model="adding_map_point_saves_tag"
          :label="$t('ticks.map_point_autosave')"
        ></v-switch>
        <v-switch
          :value="adding_tag_saves_tag"
          @change="SET_ADDING_TAG_SAVES_TAG($event || false)"
          v-model="adding_tag_saves_tag"
          :label="$t('ticks.tag_add_autosave')"
        ></v-switch>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: ['show'],
  data: () => ({
  }),
  computed: {
    ...mapGetters('ticks', [ 'adding_map_point_saves_tag', 'autosave', 'autosave_delay', 'adding_map_point_saves_tag', 'adding_tag_saves_tag' ])
  },
  methods: {
    ...mapMutations('ticks', ['SET_AUTOSAVE', 'SET_AUTOSAVE_DELAY', 'SET_ADDING_MAP_POINT_SAVES_TAG', 'SET_ADDING_TAG_SAVES_TAG'])
  }
}
</script>