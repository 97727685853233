<template>
  <v-card>
    <v-card-text class="pt-5">
      <v-slider
        :label="$t('filter.start_before')"
        @input="SET_BUFFER($event)"
        :value="buffer"
        :min="0"
        :max="30"
      >
        <template v-slot:append>
          <v-text-field
            :value="buffer"
            @input="SET_BUFFER($event)"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 50px"
          >
            <template v-slot:append>s</template>
          </v-text-field>
        </template>
      </v-slider>
      <v-slider
        :label="$t('filter.close_after')"
        @input="SET_END_BUFFER($event)"
        :value="end_buffer"
        :min="0"
        :max="30"
      >
        <template v-slot:append>
          <v-text-field
            :value="end_buffer"
            @input="SET_END_BUFFER($event)"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 50px"
          >
            <template v-slot:append>s</template>
          </v-text-field>
        </template> 
      </v-slider>
      <v-layout justify-end>
        <v-btn @click="openInGame()">
          {{$t('filter.open_in_game')}}
          <v-icon class="ml-2">mdi-play-outline</v-icon>
        </v-btn>
        <v-btn style="margin-left:auto" @click="$emit('close')" >
          {{$t('filter.apply')}}
        </v-btn>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: ['filter'],
  methods: {
    ...mapMutations('filter', [
      'SET_BUFFER',
      'SET_END_BUFFER'
    ]),
    openInGame() {
      if(this.event.game_id) this.$router.push(`/games/${this.event.game_id}?t=${this.event.endtime - this.buffer}`)
      else this.$router.push(`/videos/${this.event.video_id}?t=${this.event.endtime - this.buffer}`)
    }
  },
  computed: {
    ...mapGetters('filter', [
      'buffer',
      'end_buffer',
      'event'
    ])
  }
}
</script>