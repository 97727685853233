<template>
  <div
    :style="`width: 100%; padding: ${$vuetify.breakpoint.mobile ? '0 0' : '0 10px'}; margin: ${$vuetify.breakpoint.mobile ? '0 0 5px 0' : '25px 0px'}; border-radius: ${$vuetify.breakpoint.mobile ? '0' : '50px'};`"
  >
    <div
      class="io-bar iobar"
      @mousedown="barClicked($event)"
    >
      <div
        class="io-bar-fill"
        :style="fillStyle"
        :key="nodeKey"
      >

      </div>
      <div v-if="data">
        <div
          class="io-bar-node iobarnode"
          v-for="(point, i) in data['data']"
          :key="i + nodeKey + 'nodepoint'"
          :style="nodePlacement(point)"
        >
          <div
            class="io-bar-node-ball"
            @click.stop="seek(point.video_time)"
          >
            <div
              class="io-bar-node-ball-time"
              v-if="!hideTimes"
            >
              {{secondsToPrettyTimer(point.time_from_first)}}
            </div>
            <div
              class="io-bar-node-ball-text"
            >
              {{point.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import time from "@/utils/player/time"
export default {
  props: ['data', 'buffer', 'starttime', 'endtime', 'hideTimes'],
  mixins: [time],
  // buffer is positive
  data: () => ({
    nodeKey: 0
  }),
  mounted() {
    this.$nextTick(() => {
      this.nodeKey++;
    })
    // this.player.on('onReady', () => {
    //   alert('jea')
    // })
  },
  methods: {
    nodePlacement(point) {
      const bar = document.querySelector(".iobar")

      if(!bar) return

      const width = bar.offsetWidth

      let percent;
      if(!this.end_buffer) percent = (point.video_time - this.starttime + this.buffer) / this.length
      else percent = (point.video_time - this.starttime + this.buffer) / (this.buffer + this.end_buffer)

      return `margin-left: ${parseInt(percent * width) - 10}px`
    },
    barClicked(e) {
      const bar = document.querySelector(".iobar")
      const offset = bar.offsetLeft
      const width = bar.offsetWidth
      const percent = (e.clientX - offset) / width

      this.seek(this.starttime - this.buff + ( this.length * percent ))
    },
    mouseup() {
      alert('mouseup')
    },
    ...mapActions('player', [
      'seek'
    ])
  },
  computed: {
    fillStyle() {
      const bar = document.querySelector(".iobar")
      let curr = this.accurateCurrentTime

      if(bar) {
        const width = bar.offsetWidth
  
        const progress = curr - (this.starttime - Math.abs(this.buffer))

        if(progress < 0) return 'width: 0px;'
        const widthInPixels = Math.min(
          width,
          parseInt(progress / this.length * width)
        )
        return `width: ${widthInPixels}px;`
      }
      return 'width: 0px;'
    },
    length() {
      const buff = Math.abs(this.buffer)
      return buff * 2 + ( this.endtime - this.starttime )
    },
    buff() {
      return Math.abs(this.buffer)
    },
    ...mapGetters('player', [
      'player',
      'currentTime',
      'accurateCurrentTime'
    ])
  },
}
</script>

<style lang="scss">
  .io-bar {
    width: 100%;
    height: 10px;
    background-color: lightblue;
    position: relative;
    cursor: pointer;

    &-fill {
      background-color: #388DC8;
      border-radius: 50px;
      height: 10px;
    }

    &-node {
      position: absolute;
      top: -6px;

      &-ball {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: white;
        transition-duration: 0.2s;
        border: 1px solid black;

        &-time {
          font-size: 1.1em;
          margin-left: -11px;
          padding-top: 20px;
        }

        &-text {
          font-size: 0.5em;
          margin-left: -40px;
          margin-top: -60px;
          width: 100px;
          text-align: center;
          font-size: 0.7em;
        }

        &:hover {
          transform: scale(1.1, 1.1);
          cursor: pointer;
        }
      }
    }
  }
</style>