import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    dont_clear: false
  }),
  methods: {
    ...mapActions('player', [
      'changePlaybackRate',
      'pause',
      'play',
      'seek'
    ]),
    ...mapMutations('player', [
      'ADD_LAYER_PAUSED',
      'CLEAR_LAYERS_PAUSED'
    ]),
    ...mapActions('draw', [
      'setPauseTimeout',
      'layerClicked'
    ]),
    ...mapMutations('draw', [
      'SET_LAYER_ID',
      'SET_SELECTED_SHAPE_NAME',
      'SET_LAYER_ID'
    ]),
  },
  computed: {
    graphics() {
      return this.items || this.clip?.graphics
    },
    starttimesOrdered() {
      return this.layers?.map(i => {return {
        starttime: i.starttime, id: i.id, type: i.type, speed: i.speed, endtime: i.endtime
      }}).sort()
    },
    ...mapGetters('player', [
      'clip',
      'playing',
      'accurateCurrentTime',
      'layers_paused_already'
    ]),
    ...mapGetters('draw', [
      'layers',
      'objects',
      'layer_id'
    ])
  },
  watch: {
    'accurateCurrentTime': {
      handler(newVal, oldVal) {
        let diff = newVal - oldVal
        if(Math.abs(diff) < 0.0001) return

        let speedAlter = 1;

        if(!this.dont_clear) {
          if(this.selected_shape_name) {
            this.SET_SELECTED_SHAPE_NAME(null)
            this.$nextTick(() => {
              this.updateTransformer()
            })
          }
        }

        if(!this.dont_clear) {
          this.SET_LAYER_ID(null)
        }

        this.starttimesOrdered?.forEach(time => {
          let is_slowdown = newVal > time.starttime && newVal < time.endtime && time.type == 'slowdown'
          // time <=> layer

          if(is_slowdown) {
            speedAlter = time.speed
          }
          
          if(
            time.type != 'slowdown'
            && newVal >= time.starttime 
            && !this.layers_paused_already.includes(time.id) 
            && newVal < time.starttime + 0.3
          ) {
            this.layerClicked({
              id: time.id, 
              start_again: true
            })
            this.ADD_LAYER_PAUSED(time.id)
          } else if(
            newVal < time.starttime
            && this.layers_paused_already.includes(time.id) 
          ) {
            this.CLEAR_LAYERS_PAUSED()
          }
        })

        if(this.layers?.length) {
          this.changePlaybackRate(speedAlter)
        }
      }
    }
  }
}