<template>
  <v-card
    @click="playingClip && playingClip.id === clip.id ? closeClip() : openClip(clip); $emit('clicked', clip)" 
    link
    :ref="`clip-item-` + clip.id"
    style="transition-duration: 1s;"
    :key="clip.id"
    :class="{ 'highlighted': highlight }"
    class="mb-2"
  >
    <v-list-item dense>
      <!-- {{clip.points}} -->
      <v-list-item-icon>
        <v-icon 
          v-if="clip.is_point && clip.map_color"
          :color="clip.map_color"
        >
          mdi-tag
        </v-icon>
        <v-icon v-else-if="clip.is_point">
          mdi-tag-outline
        </v-icon>
        <v-icon v-else-if="!playingClip || clip.id != playingClip.id">
          mdi-video-outline
        </v-icon>
        <v-icon v-else>
          mdi-chevron-up
        </v-icon>
      </v-list-item-icon>
      <div
        class="my-1"
        style="
          flex-grow: 1; 
        " 
        v-if="(!clip.title || clip.title === 'New clip') && clip.tags.length"
      >
        <v-chip style="" small v-for="(tag, i) in clip.tags" :key="i + 'cliptag' + clip.id" label outlined>
          {{ tag.name }}
        </v-chip>
      </div>
      <v-list-item-title v-else>
        {{ clip.title }}
      </v-list-item-title>
      <v-chip x-small v-if="clip.num_of_tags">
        {{ clip.num_of_tags }}
      </v-chip>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!selecting"
            v-if="currentTeamId"
            v-bind="attrs"
            v-on="on"
            text
            fab
            x-small
          >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
          <input v-show="selecting" v-model="isSelected" type="checkbox" class="ml-3 mr-2" style="z-index: 4;" @click.stop="$emit('toggleSelection', clip)"/>
        </template>
        <v-list dense>
          <v-list-item 
            @click.stop="choosing = true"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('analysis.add_to_folder')}}</v-list-item-title>
          </v-list-item>
          <v-list-item 
            @click.stop="editTags = true"
            link
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('analysis.edit_tags')}}</v-list-item-title>
          </v-list-item>
          <v-list-item 
            @click="renaming = true, newName = clip.title"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('analysis.rename')}}</v-list-item-title>
          </v-list-item>
          <v-list-item 
            @click="$router.push(`/editor?clip_id=${clip.id}&from=${$route.path}`)"
            link
            dense
            v-if="!this.$vuetify.breakpoint.mobile"
          >
            <v-list-item-icon>
              <v-icon>mdi-draw</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('clip.open_in_editor')}}</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="startClipExport"
          >
            <v-list-item-icon>
              <v-icon>mdi-download</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('download_clip') }}
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item 
            @click="startDeleteClip()"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('delete')}}</v-list-item-title>
          </v-list-item>
          <v-list-item style="text-align: center;" class="mt-5">
            <share-btn resource_type="clip" :resource_id="clip.id"></share-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
    <v-expand-transition>
      <ClipInfo
        style="cursor: default;"
        @click.stop=""
        v-if="playingClip && clip.id == playingClip.id"
        :clip="clip"
      ></ClipInfo>
    </v-expand-transition>
    <ChooseFolder
      :visible="choosing"
      :value="folder"
      @closed="choosing = false"
      @chosen="addToFolder($event)"
      :text="$t('analysis.add_to_folder')"
    />
    <ConfirmationDialog
      :show="deleting"
      :subText="$t('analysis.are_you_sure_clip', [clip.title])"
      btnColor="red"
      :btnText="$t('delete')"
      :text="$t('analysis.delete_clip') + '?'"
      @decline="deleting = false"
      @accept="acceptDeleting()"
    />
    <rename-dialog
      v-model="newName"
      :visible="renaming"
      @close="renaming = false"
      @rename="rename()"
    />
    <tag-adder-dialog
      :show="editTags"
      v-if="editTags"
      v-model="editingTags"
      @close="editTags = false"
      @set="saveNewTags()"
    ></tag-adder-dialog>
    <v-dialog max-width="800" v-model="exporting">
      <export v-if="exporting" :clip_id="clip.id" @close="exporting = false"></export>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import ClipInfo from './ClipInfo'
import ChooseFolder from '@/components/folder/ChooseFolder.vue'
import TagAdderDialog from '@/components/tag/TagAdderDialog.vue'
import ShareBtn from '@/components/share/ShareBtn'
import RenameDialog from '@/components/RenameDialog.vue'
import Export from '@/components/video/export/Export.vue'

export default {
  props: ['clip', 'titleItem', 'selecting', 'highlight'],
  components: { ShareBtn, ClipInfo, ChooseFolder, ConfirmationDialog, TagAdderDialog, RenameDialog, Export },
  data: () => ({
    choosing: false,
    folder: null,
    deleting: false,
    editTags: false,
    editingTags: [],
    renaming: false,
    newName: '',
    exporting: false
  }),
  created() {
    this.editingTags = this.clip?.tags || []
  },
  methods: {
    ...mapActions('player', [
      'openClip',
      'closeClip'
    ]),
    ...mapActions('folder', [
      'addFolder'
    ]),
    ...mapActions('noti', [
      'success',
      'error',
      'info'
    ]),
    ...mapActions('clip', [
      'deleteClip',
      'editClipTags',
      'editClipTitle',
      'exportClip'
    ]),
    startClipExport() {
      this.exporting = true
    },
    addToFolder(folder) {
      this.addFolder({
        parent: folder.id,
        name: this.clip.title || 'No title',
        type: 'clip',
        clip_id: this.clip.id
      })
      .then(() => {
        this.success(this.$t('clips.clip_added'))
        this.choosing = false
      })
      .catch(e => this.error(e))
    },
    startDeleteClip() {
      this.deleting = true
    },
    acceptDeleting() {
      this.deleteClip(this.clip.id)
        .then(() => {
          this.info(this.$t('clips.clip_deleted'))
        })
        .catch(e => this.error(e))
        .finally(() => {
          this.deleting = false
        })
    },
    saveNewTags() {
      this.editClipTags({
        tags: this.editingTags.map(t => t.id),
        id: this.clip.id
      })
      .then(() => {
        this.info(this.$t('clips.tags_updated'))
      })
      .catch(e => console.log(e))
    },
    rename() {
        if(!this.newName || this.newName == this.clip.name) {
          this.renaming = false
          return
        }
  
        this.editClipTitle({id: this.clip.id, title: this.newName})
        .then(() => {
          this.info(this.$t('renamed') + '!')
        })
        .catch(e => this.error(e))
        .finally(this.renaming = false)
      },
  },
  computed: {
    ...mapGetters('player', [
      'playingClip'
    ]),
    ...mapGetters('user', [
      'currentTeamId'
    ]),
    ...mapGetters('batch', [
      'selectedItems'
    ]),
    isSelected: {
      get() {
        return this.selectedItems.some(selectedItem => selectedItem.id === this.clip.id)
      },
      set() {
        // Do nothing - we only use to check the value in a v-model directive
      }
    },
  },
  // POSSIBLE FEATURE
  // watch: {
  //   'highlight': {
  //     handler(newVal) {
  //       let ref = 'clip-item-' + this.clip.id
  //       if(newVal) this.$refs[ref].$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
  .highlighted {
    border-left: 2px solid #388DC8;
  }
</style>