<template>
  <v-sheet v-if="video">
    <!-- {{ league_config }} -->
    <!-- {{ game_players }} -->
    <v-sheet
      v-for="side in ['home', 'away']"
      :key="side + '_lineup'"
    >
      <v-sheet class="text-button">
        {{ video[`${side}_club_name`] }} - {{ side }}
      </v-sheet>
      <v-sheet 
        v-for="(player, i) in players_by_club_id(video[`${side}_club_id`])" 
        :key="i + '_' + side + '_player'"
        class="d-flex align-center py-1"
      >
        <v-text-field
          @input="changeNumber(i, side, $event)"
          style="max-width: 45px;"
          class="align-center justify-center"
          dense
          solo
          hide-details
          placeholder="No"
          :value="player.num"
        ></v-text-field>
        <v-text-field
          hide-details
          solo
          dense
          placeholder="Name"
          :value="player.player_name"
          class="px-1"
        >
  
        </v-text-field>
        <v-text-field
          placeholder="Pos"
          solo
          style="width: 20px"
          dense
          hide-details
          :value="player.pos"
        >

        </v-text-field>
      </v-sheet>
    </v-sheet>
    <v-sheet 
      class="d-flex mt-4"
    >
      <v-btn 
        v-if="lineups_config.fetch_url" @click="fetchLineups"
        outlined
      >
        Fetch lineups
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
      >
        Save
      </v-btn>
    </v-sheet>
    {{ game_players }}
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getValueFromPath } from '@/utils/config/configHelper.js'
import axios from 'axios'
// let url = `https://api.pesistulokset.fi/api/v1/public/match?id=${this.gameData.pesis_id}&apikey=wRX0tTke3DZ8RLKAMntjZ81LwgNQuSN9`

export default {
  data: () => ({
  }),
  methods: {
    ...mapActions('noti', [
      'error'
    ]),
    ...mapMutations('game', [
      'SET_LINEUPS',
      'SET_GAME_PLAYERS'
    ]),
    fetchLineups() {
      const arr = this.lineups_config.fetch_url.split('$')
      const formed_url = arr[0] + this.video.external_service_id + arr[1]

      axios.get(formed_url)
        .then(e => {
          this.addPlayers(e.data)
        })
        .catch(e => {
          this.error(e)
        })
    },
    changeNumber(index, side, newNumber) {
      let mod = { ...this.lineups }
      mod[side][index]['num'] = newNumber

      mod[side] = mod[side].sort((a, b) => {
        return a.num - b.num
      })

      this.SET_LINEUPS(mod)
    },
    addPlayers(data) {
      const fetch_format = this.lineups_config.fetch_format
      let arr_home = getValueFromPath(data, fetch_format.home_array)
      let arr_away = getValueFromPath(data, fetch_format.away_array)

      arr_home = arr_home.map(player => {
        return {
          num: getValueFromPath(player, fetch_format.num),
          pos: getValueFromPath(player, fetch_format.pos),
          picture_url: getValueFromPath(player, fetch_format.picture_url),
          external_service_id: getValueFromPath(player, fetch_format.external_service_id),
          player_name: getValueFromPath(player, fetch_format.player_name),
          league_game_id: this.$route.params.id,
          league_id: this.league.id,
          league_club_id: this.video.home_club_id
        }
      })

      arr_away = arr_away.map(player => {
        return {
          num: getValueFromPath(player, fetch_format.num),
          pos: getValueFromPath(player, fetch_format.pos),
          picture_url: getValueFromPath(player, fetch_format.picture_url),
          external_service_id: getValueFromPath(player, fetch_format.external_service_id),
          player_name: getValueFromPath(player, fetch_format.player_name),
          league_game_id: this.$route.params.id,
          league_id: this.league.id,
          league_club_id: this.video.away_club_id
        }
      })

      this.SET_GAME_PLAYERS(arr_home.concat(arr_away))
    }
  },
  computed: {
    ...mapGetters('videos', [
      'video'
    ]),
    ...mapGetters('game', [
      'lineups',
      'game_players',
      'players_by_club_id'
    ]),
    ...mapGetters('admin', [
      'leagueById'
    ]),
    league() {
      return this.leagueById(this.video ? this.video.league_id : null) || "{}"
    },
    league_config() {
      if(this.league && this.league.configuration) return JSON.parse(this.league?.configuration)
      return {}
    },
    lineups_config() {
      if(this.league && this.league.configuration) return JSON.parse(this.league?.configuration)?.lineups
      return {}
    }
  }
}
</script>