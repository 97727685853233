<template>
  <v-card flat>
    <v-btn @click="hidden = !hidden" x-small rounded style="position: absolute; z-index: 11; top: 5px; left: 5px;">
      <v-icon v-if="!hidden">
        mdi-chevron-down
      </v-icon>
      <v-icon v-else>
        mdi-chevron-up
      </v-icon>
    </v-btn>
    <div v-if="!hidden">
      <v-btn v-if="!adding_map_point_saves_tag" style="position: absolute; z-index: 11; top: 5px; right: 5px;" rounded @click="points = []; $emit('input', [])" x-small>
        <v-icon size="15">mdi-eraser</v-icon>
      </v-btn>
      <Map
        :hide_showable_shapes="hide_showable_shapes"
        :colorOverride="color"
        :key="map_key"
        :maxHeight="250"
        :maxWidth="300"
        :url="currentBaseUrl"
        :hideButtons="true"
        :disableClick="false"
        :value="value"
        @input="$emit('input', $event)"
      ></Map>
      <div  style="text-align: center;" v-if="map_bases && map_bases.length > 1">
        <v-btn x-small @click="prevMap()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn x-small @click="nextMap()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Map from '@/components/maps/Map'
export default {
  components: { Map },
  props: ['color', 'value', 'hide_showable_shapes'],
  data: () => ({
    currentBaseIndex: 0,
    map_key: 0,
    hidden: false
  }),
  created() {
    this.getMapBases()
      .then(()  => {
        this.$nextTick(()=> {
          this.map_key++
        })
      })
  },
  computed: {
    ...mapGetters('map', [ 'map_bases' ]),
    ...mapGetters('ticks', [ 'adding_map_point_saves_tag' ]),
    currentBaseUrl() {
      if(!this.map_bases) return undefined
      if(this.map_bases.length == 1) return this.map_bases[0]?.url
      return this.map_bases[this.currentBaseIndex]?.url
    }
  },
  methods: {
    ...mapActions('map', [ 'getMapBases' ]),
    nextMap() {
      this.currentBaseIndex 
      = this.currentBaseIndex + 1 == this.map_bases.length 
      ? 0 : this.currentBaseIndex + 1

      this.map_key++
    },
    toggleVisible() {
      this.hidden = !this.hidden
    },
    currentBaseId() {
      return this.map_bases[this.currentBaseIndex]?.id
    },
    prevMap() {
      this.currentBaseIndex = 
      this.currentBaseIndex == 0
      ? this.map_bases.length - 1 : this.currentBaseIndex - 1

      this.map_key++
    }
  }
}
</script>