function getValueFromPath(obj, path) {
  // Split the path by '/' to get individual keys
  const keys = path.split('/');

  // Reduce over the keys array to traverse the object
  return keys.reduce((acc, key) => {
    // If acc is undefined or null, we return undefined early
    if (acc && Object.prototype.hasOwnProperty.call(acc, key)) {
      return acc[key];
    }
    return undefined;
  }, obj); // Start with the object
}

module.exports = { getValueFromPath }
