<template>
  <v-sheet class="pa-3" height="">
    <v-row>
      <v-card-text>
        {{clip.description}}
      </v-card-text>
    </v-row>
    <v-row class="justify-center">
      <Map
        :hide_showable_shapes="true"
        v-for="base in currentClipUniqueBases"
        :key="base.id + 'clipmap'"
        :base="base"
        :value="pointsByBaseId(base.id)"
        :maxHeight="200"
        :maxWidth="200"
        :disableClicks="true"
        :hideButtons="true"
        :info="true"
      ></Map>
    </v-row>
    <div class="my-2">
      <v-icon v-if="clip.tags.length" size="20" class="mr-3">
        mdi-tag-outline
      </v-icon>
      <v-chip class="mr-1" x-small :key="tag.id" label v-for="tag in clip.tags">
        {{tag.name}}
      </v-chip>
    </div>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-around" style="justify-content: center;">
        <v-btn v-tooltip="$t('rewind')" class="mr-3" small @click.stop="openClip(clip)">
          <v-icon>
            mdi-history
          </v-icon>
        </v-btn>
        <v-btn
          class="mr-3"
          small
          @click.stop="toggle()"
        >
          <v-icon v-if="!playing">mdi-play</v-icon>
          <v-icon v-else>mdi-pause</v-icon>
        </v-btn>
        <v-btn @click.stop.prevent="$router.push(`/editor?clip_id=${clip.id}&from=${$route.path}`)" v-tooltip="$t('draw')" small>
          <v-icon>mdi-draw</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div @click.stop style="position: relative; cursor: pointer;" class="my-4" v-if="!clip.is_point">
      <v-progress-linear
        class="mt-2"
        bottom
        :value="((currentTime - clip.starttime) / (clip.endtime - clip.starttime)) * 100"
      ></v-progress-linear>
      <div :style="{marginLeft: graphicDotLeft(g.starttime) + '%', backgroundColor: g.still ? '#2196F3' : 'lightgreen'}" class="clip-info-dot" v-for="(g, i) in layers" :key="i + 'graphics-indicator'">
        <!-- type {{  g.type  }}
        <br>
        still {{  g.still  }}
        <br>
        {{  g.starttime  }}
        <br>
        {{  g.endtime  }} -->
      </div>
    </div>

  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Map from '../../../maps/Map.vue'

export default {
  components: { Map },
  props: ['clip'],
  methods: {
    ...mapActions('player', [
      'openClip',
      'closeClip',
      'toggle'
    ]),
    pointsByBaseId(id) {
      return this.clip.points?.filter(p => p.map_base_id == id)
    },
    graphicDotLeft(starttime) {
      return (starttime - this.clip.starttime) / (this.clip.endtime - this.clip.starttime) * 100
    }
  },
  computed: {
    ...mapGetters('player', [
      'currentTime',
      'playingClip',
      'playing'
    ]),
    ...mapGetters('draw', [
      'objects',
      'layers'
    ]),
    currentClipUniqueBases() {
      let bases = []
      this.clip.points.forEach(p => {
        if(!bases.map(b => b.id).includes(p.map_base_id)) {
          let base = {
            id: p.map_base_id,
            url: p.url
          }
          bases.push(base)
        }
      })
      return bases
    },
  }
}
</script>

<style lang="scss" scoped>
  .clip-info-dot {
    height: 10px;
    width: 10px;
    position: absolute;
    margin-top: -7px;
    border-radius: 50%;
  }
</style>