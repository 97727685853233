<template>
  <v-dialog 
    :value="show"
    max-width="800"
    @click:outside="$emit('close')"
  >
    <v-card class="pt-4">
      <v-card-text>
        <tag-adder
          :dialog="true"
          :hide_actions="false"
          :value="value"
          @input="$emit('input', $event)"
          @close="$emit('close')"
          @group_clicked="$emit('group_clicked', $event)"
          @tag_clicked="$emit('tag_clicked', $event)"
          :onlyGroupChoosable="onlyGroupChoosable"
          @set="$emit('set', $event)"
          :isAnalysis="isAnalysis"
          :tagging="tagging"
          :addable="addable"
          :filtering="filtering"
          :groupChoosable="groupChoosable"
          :hideExtraButtons="hideExtraButtons"
          :sport_id="sport_id"
        ></tag-adder>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TagAdder from "./TagAdder.vue"

export default {
  components: { TagAdder },
  props: ['addable', 'onlyGroupChoosable', 'show', 'value', 'isAnalysis', 'tagging', 'filtering', 'groupChoosable', 'hideExtraButtons', 'sport_id']
}
</script>