<template>
  <div>
    <span
      v-for="(s, i) in splitted"
      :key="i + 'something'"
    >
      <span class="mr-1" 
        v-if="
             s.split(' ')[0] != 'tag' 
          && s.split(' ')[0] != 'group'
          && s.split(' ')[0] != 'when'"
        >{{s}}</span>
      <span v-else-if="s.split(' ')[0] == 'tag'">
        <v-chip class="mr-1" small>
          {{ tagNameById(s.split(' ')[1]) }}
        </v-chip>
      </span>
      <span v-else-if="s.split(' ')[0] == 'group'">
        <v-chip color="primary" class="mx-1" small>
          {{ groupNameById(s.split(' ')[1]) }}
        </v-chip>
      </span>
      <div v-else-if="s.split(' ')[0] == 'when'">
        <v-chip class="mt-2" small>
          {{ $t('rules.in_next_clip') }}
        </v-chip>
      </div>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['value'],
  mounted() {
    if(!this.tags || !this.tags.length) {
      this.initTags()
    }
  },
  methods: {
    ...mapActions('tag', ['initTags']),
    tagNameById(id) {
      return this.all_tags.find(t => t.id == id)?.tag_name
    },
    groupNameById(id) {
      return this.tag_groups.find(t => t.id == id)?.group_name
    }
  },
  computed: {
    ...mapGetters('tag', ['all_tags', 'tag_groups']),
    splitted() {
      let split = this.value.split(' ')

      split.forEach((s, i) => {
        if(s === 'tag' && split[i + 1]) {
          split[i + 1] = `tag ${split[i + 1]}`
          split.splice(i, 1);
        } else if(s === 'group' && split[i + 1]) {
          split[i + 1] = 'group ' + split[i + 1]
          split.splice(i, 1);
        } else if(s === 'when' && split[i + 1]) {
          split[i + 1] = 'when ' + split[i + 1]
          split.splice(i, 1);
        }
      })

      return split
    }
  }
}
</script>