<template>
  <v-card
    @click="open()" 
    class="mb-2"
  >
    <v-list-item dense link>
      <v-list-item-icon>
        <span v-if="!playingTime || time.id !== playingTime.id">
          {{ secondsToPrettyTimer(totalTime(time.data)) }}
        </span>
        <v-icon v-else>
          mdi-chevron-up
        </v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        {{time.title}}
      </v-list-item-title>
      <v-chip x-small v-if="time.num_of_tags">
        {{ time.num_of_tags }}
      </v-chip>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!selecting"
            v-bind="attrs"
            v-on="on"
            text
            fab
            x-small
          >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
          <input v-show="selecting" v-model="isSelected" type="checkbox" class="ml-3 mr-2" style="z-index: 4;" @click.stop="$emit('toggleSelection', time)"/>
        </template>
        <v-list dense>
          <v-list-item 
            @click.stop="choosing = true"
            link
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('analysis.add_to_folder')}}</v-list-item-title>
          </v-list-item>
          <v-list-item 
            @click.stop="editTags = true"
            link
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('analysis.edit_tags')}}</v-list-item-title>
          </v-list-item>
          <v-list-item 
            @click="renaming = true, newName = time.title"
            link
          >
            <v-list-item-icon>
             <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('analysis.rename')}}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item
            link
            dense
            @click="deleting = true"
          >
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('delete')}}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <share-btn :resource_id="time.id" resource_type="time"></share-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
    <v-expand-transition>
      <TimeInfo
        v-if="playingTime && time.id == playingTime.id"
        :time="time"
      ></TimeInfo>
    </v-expand-transition>
    <ChooseFolder
      :visible="choosing"
      @closed="choosing = false"
      @chosen="addToFolder($event)"
      :text="$t('analysis.add_to_folder')"
    />
    <ConfirmationDialog
      :show="deleting"
      :subText="$t('analysis.are_you_sure_time', [time.title])"
      btnColor="red"
      btnText="delete"
      :text="$t('analysis.delete_time') + '?'"
      @decline="deleting = false"
      @accept="acceptDeleting()"
    />
    <rename-dialog
      v-model="newName"
      :visible="renaming"
      @close="renaming = false"
      @rename="rename()"
    />
    <!-- {{time}} -->
    <TagAdderDialog
      :show="editTags"
      v-if="editTags"
      v-model="editingTags"
      @close="editTags = false"
      @set="saveNewTags()"
    />
  </v-card>
</template>

<script>
import timeMixin from '@/utils/player/time'
import TimeInfo from './TimeInfo'
import { mapActions, mapGetters } from 'vuex'
import ChooseFolder from '@/components/folder/ChooseFolder.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import TagAdderDialog from '@/components/tag/TagAdderDialog.vue'
import ShareBtn from '@/components/share/ShareBtn'
import RenameDialog from '@/components/RenameDialog.vue' 

export default {
  mixins: [ timeMixin ],
  components: { TimeInfo, ChooseFolder, TagAdderDialog, ConfirmationDialog, ShareBtn, RenameDialog },
  props: ['time', 'selecting'],
  created() {
    this.editingTags = [...this.time.tags] || []
  },
  data: () => ({
    choosing: false,
    folder: {},
    deleting: false,
    editTags: false,
    editingTags: [],
    renaming: false,
    newName: ''
  }),
  methods: {
    ...mapActions('player', [
      'openTime',
      'closeTime'
    ]),
    ...mapActions('time', [
      'deleteTime',
      'editTimeTags',
      'editTimeTitle'

    ]),
    ...mapActions('noti', [
      'success',
      'error',
      'info'
    ]),
    ...mapActions('folder', [
      'addFolder'
    ]),
    saveNewTags() {
      this.editTimeTags({
        tags: this.editingTags.map(t => t.id),
        id: this.time.id
      })
      .then(() => {
        this.info(this.$t('analysis.tags_updated') + '!')
      })
      .catch(e => console.log(e))
    },
    open() {
      if(
        this.playingTime
        && this.playingTime.id == this.time.id
      ) {
        this.closeTime()
        return
      }

      this.openTime({
        time: this.time, 
        starttime: this.dataStarttime(this.time.data) - 2
      })
    },
    startDeletingTime() {
      this.deleting = true
    },
    acceptDeleting() {
      this.deleteTime(this.time.id)
        .then(() => this.info(this.$t('analysis.time_deleted')))
        .catch(e => {
          console.log(e)
          this.error(e)
        })
    },
    addToFolder(folder) {
      this.addFolder({
        parent: folder.id,
        name: this.time.title || this.$t('analysis.no_title'),
        type: 'time',
        time_id: this.time.id
      })
      .then(() => {
        this.success(this.$t('analysis.time_added_to_folder') + '!')
        this.choosing = false
      })
      .catch(e => this.error(e))
      
    },
      rename() {
        if(!this.newName || this.newName == this.time.title) {
          this.renaming = false
          return
        }
        console.log(this.time)
  
        this.editTimeTitle({id: this.time.id, title: this.newName})
        .then(() => {
          this.info(this.$t('renamed') + '!')
        })
        .catch(e => this.error(e))
        .finally(this.renaming = false)
    },
  },
  computed: {
    ...mapGetters('player', [
      'playingTime'
    ]),
    ...mapGetters('batch', [
      'selectedItems'
    ]),
    isSelected: {
      get() {
        return this.selectedItems.some(selectedItem => selectedItem.id === this.time.id)
      },
      set() {
        // Do nothing - we only use to check the value in a v-model directive
      }
    },
  }
}
</script>

<style>

</style>