<template>
  <v-sheet @click.stop="" class="pa-3" height="">
    <h4 style="text-align: center; font-size: 30px;">
      {{pretty}}
    </h4>
    <div
      v-for="point in time.data"
      :key="point.id"
    >
      {{secondsToPrettyTimer(point.time_from_first)}}      {{point.name}}
    </div>
    <v-row>
      {{time.description}}
    </v-row>
    <v-row style="text-align: center;">
      <v-col cols="12" style="justify-content: center;">
        <v-btn @click.stop="openTime({time, starttime: dataStarttime(time.data) - 2})">
          <v-icon>
            mdi-rotate-left
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-progress-linear
      class="mt-2"
      bottom
      :value="((currentTime - clip.starttime) / (clip.endtime - clip.starttime)) * 100"
    ></v-progress-linear> -->
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import timeMixin from '@/utils/player/time'

export default {
  props: ['time'],
  mixins: [ timeMixin ],
  methods: {
    ...mapActions('player', [
      'openTime',
      'closeTime'
    ])
  },
  computed: {
    ...mapGetters('player', [
      'currentTime',
      'accurateCurrentTime'
    ]),
    pretty() {
      return this.secondsToPrettyTimer(this.accurateCurrentTime - this.dataStarttime(this.time.data))
    }
  }
}
</script>
