<template>
  <div class="io-analysis-bar hideScroll">
    <!-- <h6 v-if="isAnalysis">Analyzing</h6> -->
    <v-btn-toggle
      v-if="!clipsOnly"
      mandatory
      style="width: 100% !important;"
      v-model="state"
      class="mb-6"
    >
      <v-btn
        v-tooltip="'Video clips'"
        id="side-bar-video-clip"
        small
        style="flex-grow: 1 !important;"
        :key="0"
        @click="handleSideBar('clips')"
      >
        <v-icon :size="iconSize">mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn
        v-tooltip="'Create clips'"
        id="side-bar-create-clips"
        small
        style="flex-grow: 1 !important;"
        :key="1"
        @click="handleSideBar('clipper')"
      >
        <v-icon :size="iconSize">mdi-format-horizontal-align-center</v-icon>
      </v-btn>
      <v-btn
        v-tooltip="'Tagging'"
        id="side-bar-tagging"
        small
        style="flex-grow: 1 !important;"
        :key="2"
        @click="handleSideBar('tagging')"
      >
        <v-icon :size="iconSize">mdi-pin-outline</v-icon>
      </v-btn>
      <v-btn
        v-tooltip="'Timer'"
        small
        v-if="!isAnalysis && !timesDisabled"
        :key="3"
        style="flex-grow: 1 !important;"
        @click="handleSideBar('timer')"
      >
        <v-icon :size="iconSize">mdi-timer-outline</v-icon>
        <v-icon size="10">mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-tooltip="'Lineups'"
        small
        v-if="isAnalysis"
        :key="4"
        :value="4"
        style="flex-grow: 1 !important;"
        @click="handleSideBar('lineup')"
      >
        <v-icon>mdi-account-multiple-outline</v-icon>
      </v-btn>
      <!-- <v-btn
        v-if="!isAnalysis"
        :value="4"
        style="flex-grow: 1 !important;"
        small
      >
        <v-icon :size="iconSize">mdi-dots-hexagon</v-icon>
      </v-btn> -->
    </v-btn-toggle>
    <VideoClips
      @setState="state = $event"
      ref="videoClips"
      v-if="state == 0"
    ></VideoClips>
    <Clipper @openClips="state = 0" :isAnalysis="isAnalysis" v-else-if="state == 1"></Clipper>
    <!-- <Tagging v-else-if="state == 2 && (!template || template.id != 'manual')"></Tagging> -->
    <Timer v-else-if="state == 3"></Timer>
    <game-lineups v-else-if="state == 4"></game-lineups>

    <!-- this is a v-show cause otherwise pleads to close popout would be called -->
    <add-ticks v-show="state == 2"></add-ticks>
    <!-- <AnalysisMap v-else></AnalysisMap> -->

    <!-- <AnalysisBarButton
      closedIcon="mdi-plus"
      openedIcon="mdi-close"
      text="CLIP"
    >
      <Clipper></Clipper>
    </AnalysisBarButton>
    <AnalysisBarButton
      closedIcon="mdi-plus"
      openedIcon="mdi-close" 
      text="TIME"
    >
      <Timer></Timer>
    </AnalysisBarButton> -->
    <!-- <AnalysisBarButton
      closedIcon="mdi-plus"
      openedIcon="mdi-close"
      text="MAP"
    /> -->
    <!-- <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          style="bottom: 20px; right: 20px;"
          color="primary"
          absolute
          pill
        >
          <v-icon>mdi-plus</v-icon>
          Add clip
        </v-btn>
      </template>
      <span>Tooltip</span>
    </v-tooltip> -->
  </div>
</template>

<script>
// import AnalysisBarButton from './AnalysisBarButton.vue'
import VideoClips from './VideoClips.vue'
import Clipper from './Clipper.vue'
import Timer from './Timer.vue'
// import AnalysisMap from '@/components/maps/AnalysisMaps'
// import Tagging from '@/components/video/analysisBar/Tagging'
import AddTicks from '@/components/ticks/AddTicks'
import GameLineups from '@/components/analysis/GameLineups'

import { mapGetters } from 'vuex'

export default {
  props: ['isAnalysis', 'relevantClip', 'clipsOnly'],
  created() {
    if(this.clipsOnly) {
      this.state = 0;
      return
    }

    let sidebar = this.$route.query.sidebar

    switch(sidebar) {
      case 'clips':
        this.state = 0;
        break;
      case 'clipper':
        this.state = 1;
        break;
      case 'tagging':
        this.state = 2;
        break;
      case 'timer':
        this.state = 3;
        break;
      case 'lineup':
        this.state = 4;
    }
  },
  components: { 
    // AnalysisBarButton, 
    Clipper,
    VideoClips,
    Timer,
    GameLineups,
    // AnalysisMap,
    // Tagging,
    AddTicks
  },
  methods: {
    handleSideBar(e) {
      this.$router.replace({ query: {"sidebar": e }})
    }
  },
  computed: {
    ...mapGetters('user', [
      'timesDisabled'
    ]),
    ...mapGetters('tagging', [
      'template'
    ])
  },
  data: () => ({
    state: 0,
    iconSize: 15
  })
}
</script>

<style lang="scss" scoped>
.io-analysis-bar {
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
}
</style>