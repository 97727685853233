<template>
  <div>
    <v-btn :x-small="xsmall" :small="small" :block="block" @click="open = true">
      <v-icon class="mr-3" v-if="!value">mdi-folder-plus-outline</v-icon>
      <v-icon class="mr-3" v-else>mdi-folder-outline</v-icon>
      {{
        value ? value == 'root' ? 
          $t('library.library') : value.name : $t('folder.add_to_folder')
      }}
    </v-btn>
    <choose-folder
      @chosen="folderChosen($event)"
      @closed="open = false"
      :visible="open"
      :value="value"
    ></choose-folder>
  </div>
</template>

<script>
import ChooseFolder from "./ChooseFolder.vue"
export default {
  props: ['value', 'xsmall', 'small', 'block'],
  components: { ChooseFolder },
  data: () => ({
    open: false
  }),
  methods: {
    folderChosen(f) {
      this.$emit('input', f)
      if(f) this.open = false
    }
  }
}
</script>