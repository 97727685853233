<template>
  <v-card flat>
    <div
      v-if="isAnalysis"
    >
      <v-btn 
        x-smalls
        @click="batchEditingMaps = false; editTagsClicked()"
        :color="batchEditingTags ? 'primary' : ''"
      >
        <v-icon>mdi-tag-outline</v-icon>
      </v-btn>
      <v-btn
        x-small
        @click="batchEditingTags = false; editMapsClicked()"
        :color="batchEditingMaps ? 'primary' : ''"
      >
        <v-icon>mdi-map</v-icon>
      </v-btn>
      <div v-if="batchEditingMaps">
        <v-row class="mt-5" justify="space-between">
          <v-btn @click="openLastClipForEditingClips()" class="mt-4" text>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-select
            class="mx-8"
            style="width: 40px;"
            :items="leaguewideVideoClips"
            :item-text="itemMapTitle"
            item-value="id"
            :value="playingClip.id"
            @change="openClipById($event)"
          >
            <template v-slot:item="{item}">
              {{item.title}} ({{item.points.length}})
            </template>
            <template v-slot:label>
              {{handlingIndex}} / {{leaguewideVideoClips.length}}
            </template>
          </v-select>
          <v-btn @click="openNextClipForEditingClips()" class="mt-4" text>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
        <Map
          maxHeight="400"
          :buttonIndex="chooseButtonIndex"
          maxWidth="400"
          :base="map_bases[0]"
          :value="mapPoint"
          @input="handleMapInput($event)"
        ></Map>
      </div>




      <div v-if="batchEditingTags">
        <v-row class="mt-5" justify="space-between">
          <v-btn @click="openLastClipForEditingClips()" class="mt-4" text>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-select
            class="mx-8"
            style="width: 40px;"
            :items="leaguewideVideoClips"
            :item-text="itemTitle"
            item-value="id"
            :value="playingClip.id"
            @change="openClipById($event)"
          >
            <template v-slot:item="{item}">
              {{item.title}} ({{item.tags.length}})
            </template>
            <template v-slot:label>
              {{handlingIndex}} / {{leaguewideVideoClips.length}}
            </template>
          </v-select>
          <v-btn @click="openNextClipForEditingClips()" class="mt-4" text>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-row>
        <TagAdder
          :tall="true"
          :chipsKey="chipsKey"
          class="my-5"
          :dontCloseWithEnter="true"
          chipsize="small"
          :hide_actions="true"
          :closable="false"
          :isAnalysis="true"
          :tagging="true"
          @close="batchEditingTags = false"
          @set="tagAdderClosed()"
          v-model="newTags"
        ></TagAdder>
        <v-btn :loading="savingEdited" @click="saveEditedClip()" class="mb-4" color="primary" small>Save (Enter)</v-btn>
      </div>
    </div>
    <v-card-title style="text-align: center; justify-content: center;" v-if="!batchEditingTags && !batchEditingMaps && !$vuetify.breakpoint.mobile">
      {{$t('video.video_clips')}}
    </v-card-title>
    <div class="mb-3 d-flex">
        <!-- <choose-folder-btn 
          xsmall="true"
          @input="addAllToFolder($event)"
        ></choose-folder-btn> -->
        <v-menu
          max-height="800"
          max-width="600"
          v-model="menu"
          :close-on-content-click="false"
          location="end"
        >
        <template v-slot:activator="{ on, attrs }">
          <div class="mr-6" style="display: flex; justify-content: space-between; width: 100%;">
            <div>
              <v-btn
                v-if="currentTeamId"
                class="ml-2" 
                x-small
                v-bind="attrs"
                v-on="on"
                @click="openFilter"
              >
                <v-icon>mdi-filter-outline</v-icon>
                Filter
              <v-chip outlined color="primary" class="ml-1" x-small v-if="filterTags.length">{{filterTags.length}}</v-chip>
              <v-chip v-for="item in types" :key="item" outlined color="primary" class="ml-1" x-small>{{$t('analysis.' + item)}}</v-chip>
              </v-btn>
            </div>
            <v-btn class="ml-3 mr-auto" v-if="filterTags.length || types.length" x-small text @click="filterTags = []; types = []">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div>
              <v-btn
                v-if="selecting"
                class="ml-1"
                x-small
                @click="handleBatchDelete"
              >
                {{ $t('delete') }}
                <v-icon class="ml-1" small>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                v-if="selecting"
                class="ml-1"
                x-small
                @click="handleBatchFolder"
              >
                {{$t('analysis.add_to_folder')}}
                <v-icon class="ml-1" small>mdi-folder-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="currentTeamId"
                class="ml-1"
                x-small
                @click="select"
              >
                {{ selecting ? $t('cancel') : $t('analysis.select')}}
                <v-icon small>mdi-check</v-icon>
            </v-btn>
           
          </div>
          </div>
          </template>

          <v-card style="overflow-y: scroll;">
            <v-card-text>
              <tag-adder :canFilterTypes="canFilterTypes" v-model="filterTags" @close="menu = false" :types="types" @toggleType="toggleType($event)"></tag-adder>
            </v-card-text>
          </v-card>
        </v-menu>
        <!-- <v-btn @click="showData()" class="ml-4" x-small>
          Data
        </v-btn> -->

    </div>
    <div :class="`${$vuetify.breakpoint.mobile ? 'clips-mobile' : 'clips'} pa-1`" v-if="!batchEditingTags && !batchEditingMaps">
      <div v-if="selecting" style="display: flex; justify-content: end;" class="mb-1">
        {{ $t('analysis.select_all')}}
        <input type="checkbox" class="ml-3 mr-6" v-model="allSelected" @input="selectAll"/>
      </div>
      <div>
        <TimeItem
          v-for="time in filteredVideoTimes"
          :key="time.id + 'time'"
          :id="time.id + 'time'"
          :time="time"
          :selecting="selecting"
          @toggleSelection="handleSelection(time)"
        ></TimeItem>
      </div>
      <div>
        <ClipItem
          :highlight="clip.endtime > currentTime && clip.starttime < currentTime"
          v-for="clip in ownVideoClips"
          :key="clip.id + 'clip'"
          :id="clip.id + 'clip'"
          :clip="clip"
          :selecting="selecting"
          @toggleSelection="handleSelection(clip)"
        ></ClipItem>
      </div>

      <h3 class="mb-3 ml-4" v-if="ownVideoClips.length && leaguewideVideoClips.length">eKoris</h3>
      <ClipItem
        @clicked="clipItemClicked($event)"
        v-for="clip in leaguewideVideoClips"
        :key="clip.id + 'clip'"
        :clip="clip"
      ></ClipItem>
      <div 
        v-if="!videoTimes.length && !videoClips.length"
        class="py-10"
      >
        <div 
          style="text-align: center;"
          class="mt-5 mb-4"
        >
          <div>
            {{$t('analysis.no_nothing')}}
          </div>
          <div>
            <v-btn
              @click="$emit('setState', 1)"
              color="primary"
              class="mt-10"
            >
              {{$t('analysis.create_clip')}}
              <v-icon class="ml-4">mdi-format-horizontal-align-center</v-icon>
            </v-btn>
          </div>
          <v-btn
            @click="$emit('setState', 2)"
            color="primary"
            class="mt-3"
          >
            {{$t('analysis.create_time')}}
            <v-icon class="ml-4">mdi-timer-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog scrollable max-width="1200" v-model="show_data">
      <v-card>
        <v-card-title>
          Data
        </v-card-title>
        <v-card-text>
          <DataView :clips="ownVideoClips"></DataView>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmationDialog
      :show="deleting"
      :subText="$t('analysis.are_you_sure_multiple_clip')"
      btnColor="red"
      :btnText="$t('delete')"
      :text="$t('analysis.delete_items')"
      @decline="deleting = false"
      @accept="acceptDeleting()"
    />
    <ChooseFolder
      :visible="choosing"
      @closed="choosing = false"
      @chosen="addAllToFolder($event)"
      :text="$t('analysis.add_to_folder')"
      />
  </v-card>
</template>










<script>
import { mapGetters, mapActions } from 'vuex'
import ClipItem from './clip/ClipItem'
import TimeItem from './time/TimeItem'
import TagAdder from '@/components/tag/TagAdder'
import Map from '../../maps/Map.vue'
import DataView from '@/components/data/DataView'
import a from '@/utils/axios/axios'
import ConfirmationDialog from '../../ConfirmationDialog.vue'
import ChooseFolder from '@/components/folder/ChooseFolder.vue'
// import ChooseFolderBtn from '@/components/folder/ChooseFolderBtn'

export default {
  components: { ClipItem, TimeItem, TagAdder, Map, DataView, ConfirmationDialog, ChooseFolder
    // ChooseFolderBtn 
  },
  props: ['relevantClip'],
  data: () => ({
    batchEditingMaps: false,
    batchEditingTags: false,
    newTags: [],
    handlingIndex: 0,
    chipsKey: 0,
    savingEdited: false,
    map_bases: [],
    mapPoint: [],
    settings: null,
    menu: false,
    filterTags: [],
    show_data: false,
    selecting: false,
    deleting: false,
    choosing: false,
    allSelected: false,
    types: [],
    canFilterTypes: true
  }),
  shortcuts: {
    keydown: function (e) {
      if(!this.batchEditingTags) return this.keybinds
      if(e.code == 'Enter') {
        this.saveEditedClip()
        return false
      }
      return this.keybinds
    },
    // skipForward: function() {
    //   if(this.batchEditingTags) {
    //     this.openNextClipForEditingClips()
    //     return false
    //   }
    // }
  },
  created() {
    if(!this.currentTeamId) return
    a.get(`/maps/base/team/${this.currentTeamId}`)
      .then(e => {
        this.map_bases = e.data


        // if(e.data.length == 1) {
        //   this.$emit('startDrawing', e.data[0])
        // }
      })
      .catch(e => this.error(e))
      .finally(() => this.loading = false)
  },
  beforeDestroy() {
    this.clearSelected()
  },
  methods: {
    ...mapActions('player', [
      'openClip',
      'closeClip'
    ]),
    ...mapActions('clip', [
      'editClipTags',
      'editVideoMapPoints',
      'editClipTitle'
    ]),
    ...mapActions('noti', [
      'info',
      'error',
      'success'
    ]),
    ...mapActions('batch', [
      'addItem', 
      'removeItem',
      'batchAddItems',
      'deleteSelectedItems',
      'clearItems'
    ]),
    openFilter() {
      this.clearSelected()
      this.canFilterTypes = true
      if(this.videoClips.length == 0 || this.videoTimes.length == 0) this.canFilterTypes = false
    },
    clearSelected() {
      this.selecting = false
      this.clearItems()
      this.allSelected = false;
    },
    select() {
      if(this.selecting) {
        this.clearItems()
        this.allSelected = false;
      }
      this.selecting = !this.selecting
    },
    handleSelection(item) {
      if (this.isSelected(item)) {
        this.removeItem(item);
      } else {
        this.addItem(item);
      }
    },
    selectAll() {
      if (!this.allSelected) {
        for(let clip of this.ownVideoClips) {
          this.addItem(clip)
        }
        for(let time of this.filteredVideoTimes) {
          this.addItem(time)
        }
      }
      else {
        for(let clip of this.ownVideoClips) {
          this.removeItem(clip)
        }
        for(let time of this.filteredVideoTimes) {
          this.removeItem(time)
        }
      }
    },
    isSelected(item) {
      return this.selectedItems.some(selectedItem => selectedItem.id === item.id);
    },
    handleBatchDelete() {
      this.deleting = true
    },
    acceptDeleting() {
      this.deleteSelectedItems()
      .then(() => {
        this.success(this.$t('analysis.all_deleted') + '!')
      }
      ).catch((error) => {
        this.error(error)
      }).finally(
        this.deleting = false,
        this.selecting = false,
        this.allSelected = false
      )
    },
    handleBatchFolder() {
      this.choosing = true
    },
    addAllToFolder(event) {
      if(event == null) return
      this.batchAddItems(event.id)
      .then(() => {
        this.success(this.$t('analysis.all_added') + event.name + '!')
      }
      ).catch((error) => {
        this.error(error)
      }).finally(
        this.choosing = false,
        this.selecting = false,
        this.allSelected = false
      )
    },
    showData() {
      this.show_data = true
    },
    hideData() {
      this.show_data = false
    },
    handleMapInput(points) {
      points[1] ? this.mapPoint = [points[1]] : this.mapPoint = points

      this.$nextTick(() => {
        this.saveEditedPoints()
      })
    },
    itemTitle(t) {
      return `${t.title} (${t.tags.length})`
    },
    itemMapTitle(t) {
      return `${t.title} (${t.points.length})`
    },
    editTagsClicked() {
      this.batchEditingTags = !this.batchEditingTags
      if(!this.batchEditingTags) return

      this.openClip(this.leaguewideVideoClips[0])
      this.newTags = this.playingClip?.tags
      this.handlingIndex = 0
    },
    editMapsClicked() {
      this.batchEditingMaps = !this.batchEditingMaps

      if(!this.batchEditingMaps) return

      this.openClip(this.leaguewideVideoClips[0])
      this.mapPoint = this.playingClip?.points
      this.handlingIndex = 0
    },
    saveEditedClip() {
      this.savingEdited = true
      let sett = window.localStorage.getItem('tiimi-settings')
      if(sett) this.settings = JSON.parse(sett)
      let title = this.getTitle()

      let prom1 = this.editClipTags({
        tags: this.newTags.map(t => t.id),
        id: this.playingClip.id
      })

      let prom2 = this.editClipTitle({
        title,
        id: this.playingClip.id
      })

      Promise.all([prom1, prom2])
        .then(() => {
          this.info(`ok ${this.playingClip.id}`)
          this.openNextClipForEditingClips()
          this.newTags = []
        })
        .catch(e => alert(e))
        .finally(() => this.savingEdited = false )
    },
    getTitle() {
      let title = ''
      this.settings.chosenGroups.forEach((group, i) => {
        title += this.newTags.filter(tag => tag.group_id == group)[0]?.tag_name || ''
        if(i < this.settings.chosenGroups.length - 1) title += ' '
      })

      return title.trim() || 'No title'
    },
    saveEditedPoints() {
      let params = {
        clip_id: this.playingClip?.id,
        points: this.mapPoint.map(t => { return {...t, map_base: this.map_bases[0]}})
      }

      this.editVideoMapPoints(params)
        .then(() => this.info('point ok'))
        .catch(e => this.error(e))
    },
    clipItemClicked() {
      if(!this.batchEditingTags) return
      let i = this.leaguewideVideoClips.findIndex(c => c.id == this.playingClip.id)
      this.openClipByIndex(i)
    },
    openClipByIndex(i) {
      if(i == undefined) return

      if(this.leaguewideVideoClips[i]) this.openClip(this.leaguewideVideoClips[i])
      else return

      this.handlingIndex = i

      this.$nextTick(() => {
        this.newTags = this.playingClip?.tags
        this.mapPoint = this.playingClip?.points
        this.$nextTick(() => {
          this.chipsKey++;
        })
      })
    },
    openClipById(id) {
      let i = this.leaguewideVideoClips.findIndex(c => c.id == id)
      this.openClipByIndex(i)
    },
    openNextClipForMaps() {

    },
    openNextClipForEditingClips() {
      let i = this.leaguewideVideoClips.findIndex(c => c.id == this.playingClip.id)

      if(i == undefined) return
      else if (i < this.leaguewideVideoClips.length - 1){
         this.openClip(this.leaguewideVideoClips[i + 1])
         this.handlingIndex = i + 1
      }
      else {
        this.openClip(this.leaguewideVideoClips[0])
        this.handlingIndex = 0
      }

      this.$nextTick(() => {
        this.newTags = this.playingClip?.tags
        this.mapPoint = this.playingClip?.points

        this.$nextTick(() => {
          this.chipsKey++;
        })
      })
    },
    openLastClipForEditingClips() {
      let i = this.leaguewideVideoClips.findIndex(c => c.id == this.playingClip.id)

      if(i == undefined) return
      else if (i > 0){
         this.openClip(this.leaguewideVideoClips[i - 1])
         this.handlingIndex = i - 1
      }
      else return

      this.$nextTick(() => {
        this.newTags = this.playingClip?.tags
        this.mapPoint = this.playingClip?.points

        this.$nextTick(() => {
          this.chipsKey++;
        })
      })
    },
    contains(tagIds, clipTagIds) {
      // jos filtterissä ei ole tageja groupissa niin klipin tagit groupissa ei vaikuta
      if(!tagIds || !tagIds.length) return true
      if(!clipTagIds || !clipTagIds.length) return false
      // jos filtterissä on groupissa tag ja clipissä ei ole tagia tästä groupista palautetaan false
      // tagIds: 

      return tagIds.some(element => {
        return clipTagIds.includes(element);
      });
    },
    clipIsIn(clip) {
      let group_ids = this.filterTags.map(t => t.group_id)
      group_ids = group_ids.filter(this.onlyUnique);
      let isIn = true;
      group_ids.forEach(group => {
        let tagIds = this.filterTags.filter(tag => tag.group_id == group).map(t => t.id)
        let clipTagIds = clip.tags.filter(t => t.group_id == group).map(t => t.id)

        const hasJoin = this.contains(tagIds, clipTagIds)

        if(!hasJoin) isIn = false;
        return
      })

      return isIn
    },
    onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    },
    toggleType(event) {
        if(this.types.includes(event)) {
          this.types = this.types.filter(item => item !== event)
          return
        }
        this.types.push(event)
    }
  },
  computed: {
    ...mapGetters('videos', [
      'videoClips',
      'videoTimes',
    ]),
    ...mapGetters('player', [
      'playingClip',
      'currentTime',
      'playingTime'
    ]),
    ...mapGetters('user', [
      'currentTeamId'
    ]),
    ...mapGetters('batch', [
      'selectedItems'
    ]),
    chooseButtonIndex() {
      if(!this.playingClip) return 0

      let tags = this.playingClip.tags
      let shot_made = tags.findIndex(t => t.name == 'Shot made') >= 0
      let shot_blocked = tags.findIndex(t => t.name == 'Shot blocked') >= 0

      return shot_made ? 1 : shot_blocked ? 3 : 0
    },
    ownVideoClips() {
      if (this.types.length == 0 || this.types.includes('clips')) {
      return this.videoClips.filter(v => !v.leaguewide).filter(c => this.clipIsIn(c))
      }
      return []
    },
    filteredVideoTimes() {
      if (this.types.length == 0 || this.types.includes('times')) {
      return this.videoTimes.filter(v => !v.leaguewide).filter(t => this.clipIsIn(t))
      }
      return []
    },
    leaguewideVideoClips() {
      return this.videoClips.filter(v => v.leaguewide)
    },
    isAnalysis() {
      return this.$route.path.includes('analysis')
    },
    // filterByTags(tags) {

    // }
  }
}
</script>

<style lang="scss" scoped>
  .clips {
    overflow-y: scroll;
    flex-grow: 1;
    max-height: calc(100vh - 200px);
  }
  .clips-mobile {
    overflow-y: scroll;
    flex-grow: 1;
    max-height: calc(100vh - 336px);
  }
  
</style>